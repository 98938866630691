import { createStore } from "vuex";

import VuexPersist from "vuex-persist";
import env from "./modules/Environment";
import Translations from "./modules/Translations";
import TableHeaders from "./modules/TableHeaders";
import Reminders from "./modules/Reminders";
import Formatting from "./modules/Formatting";
import me from "./modules/Authentication_module";
import notes from "./modules/Notes_module";
import actions from "./modules/Actions_module";
import emailKit from "./modules/IOKits/email.kit";
import dataviewKit from "./modules/IOKits/dataview.kit";
import dataKit from "./modules/IOKits/data.kit";
import noteKit from "./modules/IOKits/note.kit";
import invoiceKit from "./modules/IOKits/invoice.kit";
import exportKit from "./modules/IOKits/export.kit";
import importKit from "./modules/IOKits/import.kit";
import financialKit from "./modules/IOKits/financial.kit";
import flowKit from "./modules/IOKits/flow.kit";
import organizationalKit from "./modules/IOKits/organizational.kit";
import todoKit from "./modules/IOKits/todo.kit";
import EventBus from "@/store/modules/EventBus/EventBus";

const vuexPersist = new VuexPersist({
  key: "my-app",
  storage: window.localStorage,
  reducer: (state) => ({
    me: state.me,
  }),
});

export default createStore({
  state: {
    route: "",
    emitter: EventBus
  },
  mutations: {
    SET_ROUTE(state, route) {
      state.route = route;
    },
  },
  actions: {},
  modules: {
    env,
    Translations,
    TableHeaders,
    Reminders,
    Formatting,
    me,
    notes,
    emailKit,
    noteKit,
    actions,
    dataKit,
    dataviewKit,
    invoiceKit,
    importKit,
    exportKit,
    financialKit,
    flowKit,
    organizationalKit,
    todoKit,
  },
  plugins: [vuexPersist.plugin],
});
