import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async postSend({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "email.kit.domain", {
      root: true,
    })}/api/send`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postRaw({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "email.kit.domain", {
      root: true,
    })}/api/raw`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postHtml({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "email.kit.domain", {
      root: true,
    })}/api/html`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postQueue({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "email.kit.domain", {
      root: true,
    })}/api/queue`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postBulkQueue({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "email.kit.domain", {
      root: true,
    })}/api/bulk/queue`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postHelpersProcessEml({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "email.kit.domain", {
      root: true,
    })}/api/helpers/process/eml`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getHelpersEmlJson({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "email.kit.domain", {
      root: true,
    })}/api/helpers/retrieve/eml/json`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        postSendStatus: (state) => state.postSendStatus,
    postRawStatus: (state) => state.postRawStatus,
    postHtmlStatus: (state) => state.postHtmlStatus,
    postQueueStatus: (state) => state.postQueueStatus,
    postBulkQueueStatus: (state) => state.postBulkQueueStatus,
    postHelpersProcessEmlStatus: (state) => state.postHelpersProcessEmlStatus,
    getHelpersEmlJsonStatus: (state) => state.getHelpersEmlJsonStatus,
  },
};