<template>
  <div id="NewItemModal">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card" :style="{}">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ t(`Create new ${list.name} action`) }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button
            class="delete"
            aria-label="close"
            v-if="!isLoading"
            @click="close"
          ></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-4">
          <div class="field m-0">
            <div class="control p-3">
              <textarea
                v-model="description"
                rows="3"
                class="textarea"
                :placeholder="t('description')"
              />
            </div>
          </div>
          <div class="field m-0">
            <div class="control p-3">
              <Datepicker
                :enableTimePicker="false"
                inline
                autoApply
                v-model="date"
              />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot p-0">
          <span
            class="card-footer-item has-text-danger is-clickable"
            @click="close"
          >
            {{ t("Cancel") }}
          </span>
          <span
            class="card-footer-item has-text-link is-clickable"
            @click="send"
          >
            {{ t(`Create new ${list.name}`) }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables";
.dp__menu {
  border-radius: $radius-large !important;
}
</style>

<script>
import Datepicker from "vue3-date-time-picker";
export default {
  name: "NewItemModal",
  components: {
    Datepicker,
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      list: {
        name: "",
      },
      owner_identifier: null,

      description: "",
      date: new Date(),

      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  computed: {},
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.list.name = values.list.name;

      this.isOpen = true;
    },
    close() {
      this.$emit("close");
      this.isOpen = false;
    },

    async send() {
      let payload = {
        name: this.list.name,
        description: this.description,
        scheduled_at: this.date.toISOString().split("T")[0],
        is_flagged: false,
        owner_company:
            this.$route.params.company_id ??
            (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
      };

      try {
        let data = await this.$store.dispatch(
          "todoKit/putListItemCreate",
          payload
        );

        if (!data.success) {
          throw data;
        }

        this.close();
        this.description = '';
        // todo: add a popup
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
