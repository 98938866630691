<template>
  <!-- 
  props:
    name | required: true | type: String || Number
    value | required: false | type: String || Number
    classes | default: () => ["is-dark"]
 -->
  <div class="tags has-addons is-flex-shrink-0">
    <span class="tag is-rounded has-text-weight-bold" :class="classes"
      >{{ name }}
    </span>

    <span
      class="tag is-rounded is-light"
      :class="[
        ...(typeof classes === 'object' ? classes : [classes]),
        { 'is-clickable': editable },
      ]"
      @click="openUpdate"
    >
      <template v-if="!isUpdateOpen">
        <span>
          {{ value }}
        </span>
        <span class="icon ml-1" v-if="editable">
          <span class="fas fa-edit"></span>
        </span>
      </template>
      <div class="field has-addons" v-else>
        <div class="control">
          <input
            type="number"
            class="input is-small"
            step="0.01"
            v-model="newValue"
            :disabled="loading"
          />
        </div>
        <div class="control ml-2">
          <button
            :disabled="loading"
            class="button is-small is-danger has-text-weight-bold"
          >
            cancel
          </button>
        </div>
        <div class="control ml-2">
          <button
            class="button is-small is-primary has-text-weight-bold"
            @click="update"
            :disabled="loading"
          >
            <span v-if="!loading">save</span>
            <span class="icon" v-else>
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </button>
        </div>
      </div>
    </span>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { useToast } from "vue-toastification";

export default {
  data: () => ({
    loading: false,
    isUpdateOpen: false,
    newValue: ""
  }),
  props: {
    name: {
      required: true,
      type: String || Number
    },
    value: {
      required: false,
      type: String || Number,
      default: () => ""
    },
    rawValue: {
      required: false
    },
    classes: {
      default: () => ["is-dark"]
    },
    editable: {
      required: false,
      default: false
    },
    ccid: {
      required: false,
      default() {
        return "";
      }
    }
  },
  watch: {},
  created() {
    this.$toast = useToast();

    this.newValue = this.rawValue;
  },
  methods: {
    openUpdate() {
      this.isUpdateOpen = true;
    },
    async update() {
      console.log(this.$parent.$props);
      this.loading = true;
      try {
        const result = await this.axios.post(
          `${await this.$store.dispatch("getEnv", "design.kit.domain", {
            root: true
          })}/api/collection/case`,
          {
            collection_case: this.ccid,
            [this.name.replace("collection_", "")]: this.newValue
          }
        );

        if (result.data.success) {
          this.$parent.$parent.getCustomerData();
          this.isUpdateOpen = false;
          this.$toast.success("updated successfully");
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    }
  }
};
</script>
