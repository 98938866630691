import InvoiceOptions from "@/components/Dynamic/TableObjects/InvoiceOptions";
import {
  // defineAsyncComponent,
  markRaw,
} from "vue";

export default {
  namespaced: true,
  state: {
    headers: {
      cm_incaze_nl_all_customers_ICMS: [
        {
          title: "Case ID",
          name: "collection_case_identifier",
          abbr: "Case ID",
        },
        {
          title: "identifier",
          name: "company_id", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) },
          abbr: "id",
        },
        {
          title: "name",
          name: "company_name", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) },
        },
        {
          title: "open amount",
          name: "open_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
        {
          title: "overdue amount",
          name: "overdue_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
      ],
      cm_incaze_nl_all_customers: [
        {
          title: "identifier",
          name: "company_id", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) },
          abbr: "id",
        },
        {
          title: "name",
          name: "company_name", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) },
        },
        {
          title: "open amount",
          name: "open_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
        {
          title: "overdue amount",
          name: "overdue_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
      ],
      cm_incaze_nl_all_customers_mobile: [
        {
          title: "identifier",
          name: "company_id", anchor(ctx, row) { ctx.$router.push({ name: 'InvoiceCustomer',  params: { parent_identifier: row.company_id, company_id: row.company_id, ...row }, }) },
          abbr: "id",
        },
        {
          title: "name",
          name: "company_name", anchor(ctx, row) { ctx.$router.push({ name: 'InvoiceCustomer',  params: { parent_identifier: row.company_id, company_id: row.company_id, ...row }, }) },
        },
      ],
      cm_incaze_nl_all_invoices: [
        {
          title: "invoice number",
          name: "invoice_number",
          abbr: "#",
        },
        {
          title: "invoice amount",
          name: "invoice_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
        {
          title: "issued at",
          name: "issued_at",
        },
        {
          title: "overdue at",
          name: "overdue_at",
        },
        {
          title: "#",
          component: {
            component: markRaw(InvoiceOptions),
          },
        },
      ],
      cm_incaze_nl_all_disputes: [
        {
          title: "dispute",
          name: "dispute_name",
          abbr: "#",
        },
        {
          title: "disputed amount",
          name: "disputed_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
        {
          title: 'reason',
          name: 'basis',
        },
        {
          title: "age (days)",
          name: "age_days",
        },
        {
          title: "customer id",
          name: "buyer_company_id", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.buyer_company_id, ...row }, }) }
        },
        {
          title: "customer name",
          name: "buyer_company_name", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.buyer_company_id, ...row }, }) }
        },
        {
          title: "issued by",
          name: "issued_by",
        },
        {
          title: 'last note',
          name: "last_note"
        }

      ],
      cm_incaze_nl_all_payment_promises: [
        {
          title: "promise",
          name: "promise_name",
          abbr: "#",
        },
        {
          title: "promised amount",
          name: "promise_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
        {
          title: "customer id",
          name: "buyer_company_id", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) }
        },
        {
          title: "customer name",
          name: "buyer_company_name", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) }
        },
        {
          title: "issued by",
          name: "issued_by",
        },

        {
          title: "issued at",
          name: "created_at",
          transformer: "getFormattedDateTime",
        },
        {
          title: 'last note',
          name: "last_note"
        }
      ],
      cm_incaze_nl_overdue_payment_promises: [
        {
          title: "promise",
          name: "promise_name",
          abbr: "#",
        },
        {
          title: "promised amount",
          name: "promise_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
        {
          title: "customer id",
          name: "buyer_company_id",
          anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) }
        },
        {
          title: "customer name",
          name: "buyer_company_name",
          anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) }
        },
        {
          title: "issued by",
          name: "issued_by",
        },
        {
          title: "next payment",
          name: "next_payment",
        },
        {
          title: "issued at",
          name: "created_at",
          transformer: "getFormattedDateTime",
        },
        {
          title: 'last note',
          name: "last_note"
        }
      ],
      cm_incaze_nl_all_payment_plans: [
        {
          title: "plan",
          name: "plan_name",
          abbr: "#",
        },
        {
          title: "customer id",
          name: "buyer_company_id",
          anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) }
        },
        {
          title: "customer name",
          name: "buyer_company_name",
          anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) }
        },
        {
          title: "issued by",
          name: "issued_by",
        },
        {
          title: "next payment",
          name: "next_payment",
        },

        {
          title: "issued at",
          name: "created_at",
          transformer: "getFormattedDateTime",
        },
        {
          title: 'last note',
          name: "last_note"
        }
      ],
      cm_incaze_nl_all_imports: [
        { title: "import",  name: "import_type",  abbr: "import", },
        { title: "imported at",  name: "imported_at",  abbr: "imported at", },
        { title: "finished at",  name: "finished_at",  abbr: "finished at", },
        { title: "cells processed",  name: "total_cells_processed",  abbr: "cells processed", },
        { title: "imported by",  name: "imported_by",  abbr: "imported by", },
      ],
      cm_incaze_nl_reminders_logging: [
        { title: "company_id",  name: "company_id",  abbr: "company id", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) } },
        { title: "company_name",  name: "company_name",  abbr: "company name", anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) } },
        { title: "reminder_type",  name: "reminder_type",  abbr: "reminder_type", },
        { title: "sent_at",  name: "sent_at",  abbr: "sent at", },
        { title: "sent_by",  name: "sent_by",  abbr: "sent by", },
      ],
      cm_incaze_nl_collection_cases_without_balance: [
        { title: 'seller', name: 'parent_identifier', abbr: 'seller',   },
        { title: 'debtor id', name: 'company_id', abbr: 'debtor id', anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) } },
        { title: 'debtor name', name: 'company_name', abbr: 'debtor name', anchor(ctx, row) {
          ctx.$router.push({
            name: 'Customer',
            params: { id: row.company_id, ...row },
          })
          } },
        { title: 'Collection Amount', name: 'collection_amount', abbr: 'Collection Amount', transformer: "getFormattedNumber", },
        { title: 'Costs', name: 'collection_costs', abbr: 'Costs', transformer: "getFormattedNumber", },
        { title: 'Interests', name: 'collection_interests', abbr: 'Interests', transformer: "getFormattedNumber", },
      ],

      cm_incaze_nl_collection_cases_new: [
        { title: 'seller', name: 'parent_identifier', abbr: 'seller' },
        { title: 'debtor id', name: 'company_id', abbr: 'debtor id', anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) } },
        { title: 'debtor name', name: 'company_name', abbr: 'debtor name', anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) } },
        { title: 'Collection Amount', name: 'collection_amount', abbr: 'Collection Amount',transformer: "getFormattedNumber", },
        { title: 'Costs', name: 'collection_costs', abbr: 'Costs',transformer: "getFormattedNumber", },
        { title: 'Interests', name: 'collection_interests', abbr: 'Interests', transformer: "getFormattedNumber", },
      ],


      cm_incaze_nl_all_customers_with_open_balance_bt0: [
        { title: 'debtor id', name: 'company_id', abbr: 'debtor id', anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) } },
        { title: 'debtor name', name: 'company_name', abbr: 'debtor name', anchor(ctx, row) { ctx.$router.push({ name: 'Customer',  params: { id: row.company_id, ...row }, }) } },

        {
          title: "open amount",
          name: "open_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
        {
          title: "overdue amount",
          name: "overdue_amount",
          transformer: "getFormattedNumber",
          align: "right",
        },
        {
          title: "last payment date",
          name: "last_payment_date",
          transformer: "getFormattedDate",
        },
        {
          title: "last payment amount",
          name: "last_payment_amount",
          transformer: "getFormattedNumber",
        },
        {
          title: "Last Note Date",
          name: "last_note_created_at",
          transformer: "getFormattedDate",
        },
        {
          html(value) {
            return value;
          } ,
          title: "Last Note",
          name: "last_note_description",
        },

      ],
    },
    rowHandlers: {
      cm_incaze_nl_all_disputes: null, // todo:
    },
  },
  mutations: {},
  actions: {},
  getters: {
    getHeaders: (state) => (table) => {
      return state.headers[table] ?? [];
    },
  },
};
