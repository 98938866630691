import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async putInvoice({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "invoice.kit.domain", {
      root: true,
    })}/api/invoice`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getInvoices({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "invoice.kit.domain", {
      root: true,
    })}/api/invoices`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
async getNextFormattedInvoiceForCustomer({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "invoice.kit.domain", {
      root: true,
    })}/api/formatter/next/invoice/identifier`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getDomainInvoices({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "invoice.kit.domain", {
      root: true,
    })}/api/domain/invoices`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getCompanyInvoices({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "invoice.kit.domain", {
      root: true,
    })}/api/company/invoices`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getOwnedCompanyInvoices({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "invoice.kit.domain", {
      root: true,
    })}/api/owned/company/invoices`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postSendInvoicesByEmail({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "invoice.kit.domain", {
      root: true,
    })}/api/send/invoices/pdf/email`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        putInvoiceStatus: (state) => state.putInvoiceStatus,
    getInvoicesStatus: (state) => state.getInvoicesStatus,
    getDomainInvoicesStatus: (state) => state.getDomainInvoicesStatus,
    getCompanyInvoicesStatus: (state) => state.getCompanyInvoicesStatus,
    getOwnedCompanyInvoicesStatus: (state) => state.getOwnedCompanyInvoicesStatus,
    postSendInvoicesByEmailStatus: (state) => state.postSendInvoicesByEmailStatus,
  },
};