<template>
  <div class="DynamicTable" ref="dTable">
    <table
      class="table is-fullwidth is-size-6 is-hoverable"
      :class="{
        'is-clickable': typeof rowClickHandler === 'function',
        'is-mobile': tableWidth < breakingPoint,
      }"
    >
      <thead>
        <tr>
          <th
            v-for="header in theHeaders"
            :key="header.title"
            class="has-text-weight-bold"
          >
            <abbr v-if="header.abbr" :title="t(header.title)">{{
              t(header.abbr)
            }}</abbr>
            <template v-else>
              {{ t(header.title) }}
            </template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="rows.length === 0 && !loading">
          <td :colspan="headers.length">{{ t("No results available.") }}</td>
        </tr>
        <tr
          v-for="(row, index) in rows"
          :class="{ 'has-background-link-light': row.selected }"
          :key="row[0] ?? index"
          @click="rowClickHandler(row)"
        >
          <td
            :data-header="header.title"
            v-for="header in theHeaders"
            :key="row[0] + (header.name ?? '')"
            :class="{
              'has-text-left': header.align === 'left',
              'has-text-right': header.align === 'right',
            }"
          >
            <template v-if="header.html">
              <span v-html="header.html(row[header.name])"></span>
            </template>
            <template v-else-if="header.anchor">
              <a href="#" @click.prevent.stop="header.anchor(this, row)">
                <span v-html="row[header.name]"></span>
              </a>
            </template>
            <template v-else-if="header.transformer">
              <template v-if="header.transformer === 'getFormattedNumber'">
                <span
                  v-html="
                    this[header.transformer](row[header.name], row.currency)
                  "
                ></span>
              </template>
              <template v-else>
                <span
                  v-html="this[header.transformer](row[header.name])"
                ></span>
              </template>
            </template>
            <template v-else-if="header.component">
              <component
                :is="header.component.component"
                v-bind="
                  Object.assign({ row: row }, header.component.bind ?? {})
                "
                v-on="Object.assign({ row: row }, header.component.on ?? {})"
              />
            </template>
            <template v-else>
              {{ row[header.name] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <nav
      class="pagination is-rounded is-small"
      role="navigation"
      aria-label="pagination"
      v-if="isPaginated && (rows.length > 0 || loading)"
    >
      <ul class="pagination-list">
        <template v-if="!(Number(pageData.currentPage) - 2 < 1)">
          <li>
            <a
              @click="setNewPage(1)"
              :class="['pagination-link', 'button', { 'is-loading': loading }]"
              aria-label="Goto page 1"
              >1</a
            >
          </li>
          <li><span class="pagination-ellipsis">&hellip;</span></li>
        </template>

        <li>
          <a
            :class="['pagination-link', 'button', { 'is-loading': loading }]"
            @click="setNewPage(Number(pageData.currentPage) - 1)"
            v-if="!(Number(pageData.currentPage) - 1 < 1)"
            >{{ Number(pageData.currentPage) - 1 }}</a
          >
        </li>
        <li>
          <a
            :class="[
              'pagination-link',
              'button',
              'is-current',
              { 'is-loading': loading },
            ]"
            aria-current="page"
            >{{ Number(pageData.currentPage) }}</a
          >
        </li>
        <li>
          <a
            :class="['pagination-link', 'button', { 'is-loading': loading }]"
            @click="setNewPage(Number(pageData.currentPage) + 1)"
            v-if="!(Number(pageData.currentPage) >= Number(pageData.lastPage))"
            >{{ Number(pageData.currentPage) + 1 }}</a
          >
        </li>
        <template v-if="!(Number(pageData.currentPage) + 2 >= Number(pageData.lastPage))">
          <li><span class="pagination-ellipsis">&hellip;</span></li>
          <li>
            <a
              :class="['pagination-link', 'button', { 'is-loading': loading }]"
              @click="setNewPage(Number(pageData.lastPage))"
              >{{ Number(pageData.lastPage) }}</a
            >
          </li>
        </template>
      </ul>
      <span
        :class="['pagination-previous', 'button', { 'is-loading': loading }]"
        v-if="!(Number(pageData.currentPage) - 1 < 1)"
        @click="setNewPage((page) => page - 1)"
        >{{ t("Previous") }}</span
      >
      <span
        :class="['pagination-next', 'button', { 'is-loading': loading }]"
        v-if="!(Number(pageData.currentPage) + 1 >= Number(pageData.lastPage))"
        @click="setNewPage((page) => page + 1)"
        >{{ t("Next page") }}</span
      >
    </nav>
    <div
      class="dropdown is-pulled-right"
      :class="{
        'is-active': alternativeHeadersOpen,
      }"
    >
      <div class="dropdown-trigger">
        <button
          class="button is-small"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          @click="alternativeHeadersOpen = !alternativeHeadersOpen"
        >
          <span>Select alternative columns (beta)</span>
          <span class="icon is-small">
            <i
              class="fas fa-angle-down"
              v-if="!alternativeHeadersOpen"
              aria-hidden="true"
            ></i>
            <i
              class="fas fa-times"
              v-if="alternativeHeadersOpen"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a
            @click="toggleHeader(alternativeHeader)"
            class="dropdown-item"
            :class="{
              'is-active':
                typeof selectedHeaders[alternativeHeader] !== 'undefined',
            }"
            v-for="alternativeHeader in Object.keys(rows[0] ?? {})"
            :key="alternativeHeader"
          >
            <span class="icon">
              <span
                class="fa fa-check"
                v-if="typeof selectedHeaders[alternativeHeader] !== 'undefined'"
              ></span>
              <span
                class="fa fa-times"
                v-if="typeof selectedHeaders[alternativeHeader] === 'undefined'"
              ></span>
            </span>
            <span>{{ alternativeHeader }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
.has-background-link-light {
  background-color: #d3dbff !important;
}
.table.is-mobile {
  //display: inline-flex;

  thead {
    //display: none;
    border: 1px solid $grey-light;
  }
  tbody {
    width: 100%;
    tr {
      //display: table;
      width: 100%;
      margin-bottom: 2em;
      border: 1px solid $grey-light;
      border-radius: $radius-small;
      td {
        display: table-cell;
        text-align:right!important;
        //justify-content: space-between;
        //border-bottom-width: 1px;
        &::before {
          //content: attr(data-header);
          font-weight: 700;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "Table",
  data() {
    return {
      tableWidth: 0,
      t: this.$store.getters["Translations/getTranslations"](
        `${this.$parent.$options.name}${this.$options.name}`
      ),
      theHeaders: [],
      alternativeHeadersOpen: false
    };
  },
  computed: {
    selectedHeaders() {
      let headers = {};
      for (let i = 0; i < this.theHeaders.length; i++) {
        headers[this.theHeaders[i].name] = true;
      }
      return headers;
    }
  },
  props: {
    loading: {
      required: false,
      type: Boolean,
      default: () => false
    },
    isPaginated: {
      required: false,
      type: Boolean,
      default: true
    },
    pageData: {
      required: false,
      type: Object,
      default: () => ({
        currentPage: 1,
        lastPage: 20
      })
    },
    rowClickHandler: {
      required: false,
      type: Function,
      default: function() {
        return false;
      }
    },
    headers: {
      required: false,
      type: Array,
      default: () => [
        {
          title: "identifier",
          name: "identifier",
          abbr: "id"
        },
        {
          title: "title",
          name: "title"
        }
      ]
    },
    rows: {
      required: false,
      type: Array,
      default: () => [
        {
          identifier: "0",
          title: "a fun title"
        },
        {
          identifier: "1",
          title: "a not fun title"
        },
        {
          identifier: "2",
          title: "a !not fun title"
        }
      ]
    },
    breakingPoint: {
      required: false,
      type: Number,
      default: () => 1040
    }
  },
  watch: {
    rows: {
      deep: true,
      handler() {
        this.$nextTick(
          () =>
            (this.tableWidth = this.$refs.dTable
              ? this.$refs.dTable.clientWidth
              : 0)
        );
      }
    }
  },
  created() {
    this.theHeaders = this.headers;
  },
  mounted() {
    window.onresize = () =>
      (this.tableWidth = this.$refs.dTable ? this.$refs.dTable.clientWidth : 0);
    this.$nextTick(
      () =>
        (this.tableWidth = this.$refs.dTable
          ? this.$refs.dTable.clientWidth
          : 0)
    );
    this.theHeaders = this.headers;
  },
  methods: {
    toggleHeader(header) {
      let exists = this.theHeaders.filter(function(h) {
        return h.name === header;
      });

      if (exists.length >= 1) {
        this.theHeaders = this.theHeaders.filter(function(h) {
          return h.name !== header;
        });
      } else {
        this.theHeaders.push({
          title: header,
          name: header
        });
      }
    },
    setNewPage(newPage) {
      switch (typeof newPage) {
        case "number":
          this.$emit("newPage", newPage);
          break;
        case "function":
          this.$emit("newPage", newPage(Number(this.pageData.currentPage)));
          break;

        default:
          break;
      }
    },
    getFormattedNumber(number, currency = "EUR") {
      return this.$store.getters[`Formatting/getFormattedNumber`](
        number,
        currency
      );
    },
    getFormattedDateTime(date) {
      return this.$store.getters[`Formatting/getFormattedDateTime`](date);
    },
    getFormattedDate(date) {
      return this.$store.getters[`Formatting/getFormattedDate`](date);
    },
  }
};
</script>
