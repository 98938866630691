import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;

import * as timeago from "timeago.js";

export default {
  state: () => ({
    notes: [],
      loading: false,
  }),
  mutations: {
    addNotes(state, newNotes) {
      state.notes = [...state.notes, ...newNotes];
    },
    newNotes(state, newNotes) {
      state.notes = newNotes;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async getNotes({ commit, dispatch, rootState }) {
        commit("setLoading", true);
      let notes = await dispatch(
        "dataviewKit/getViewData",
        {
          view: "cm_incaze_nl_all_notes",
          page: 1,
          itemsPerPage: 100,
          parameters: {
            owner_identifier: (
              rootState.me.activeSeller ??
              rootState.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
          },
          filters: {
            company_id: rootState.route.params?.company_id,
          },
        },
        { "content-type": "application/json" }
      );

      if (notes?.success) {
        commit(
          "newNotes",
          notes.data.map((note) => {
            return {
              ...note,
              timeago: timeago.format(new Date(note.created_at)),
            };
          })
        );
      } else {
        throw notes;
      }
        commit("setLoading", false);

    },

    async startTimeAgo({ commit, state }) {
      setInterval(() => {
        commit(
          "newNotes",
          state.notes.map((note) => {
            return {
              ...note,
              timeago: timeago.format(new Date(note.created_at)),
            };
          })
        );
      }, 10 * 1000);
    },
  },
  getters: {},
};
