<template>
  <div ref="app" class="columns" v-if="isLoggedIn && $route.name !== 'Login'">
      <template v-if="isMobile">
        <div class="column is-relative is-8 is-12-touch p-2">
            <div class="mobile-logo">
              <img
                  src="../src/assets/logo_Simpeldeb.png"
                  class="is-block"
                  alt="loginGraphic"
                  @click="navigateToHome"
              />
            </div>
    <!--        <NavbarMobile v-if="!(direction !== 'none' || !direction)" @routeClick="changeRoute" />-->

            <button v-if="direction !== 'none'" class="button is-dark is-inverted"
            @click="navigateBack">&laquo;
            Back
            </button>

            <router-view v-slot="{ Component }" >
              <transition :name="direction + '-slide'">
                <div id="viewContainer" class="" :key="Component">
                  <div class="box">
                    <component :is="Component" />
                  </div>
                </div>
              </transition>
            </router-view>
        </div>
      </template>
      <template v-else>
        <div class="column is-relative is-8 is-12-touch p-6">
            <Navbar @routeClick="changeRoute" />
            <router-view v-slot="{ Component }">
              <transition :name="direction + '-slide'">
                <div id="viewContainer" class="px-6" :key="Component">
                  <div class="box">
                    <component :is="Component" />
                  </div>
                </div>
              </transition>
            </router-view>
        </div>
      </template>


    <div
      class="column has-background-grey-light is-0-touch is-4 is-hidden-touch p-6  sidepanel__container"
    >
      <SidePanel />
    </div>

    <ViewDisputeModal ref="ViewDisputeModal" />
    <ViewPaymentPromiseModal ref="ViewPaymentPromiseModal" />
    <ViewPaymentPlanModal ref="ViewPaymentPlanModal" />
  </div>
  <router-view v-else />
</template>

// For the slide left/right
<style lang="scss">
.to-left,
.to-right {
  &-slide-enter-active,
  &-slide-leave-active {
    transition: right 0.75s ease-out, left 0.75s ease-out;
  }
}
.to-left {
  &-slide-enter-to {
    position: absolute;
    right: 0;
  }

  &-slide-enter-from {
    position: absolute;
    right: -100%;
  }

  &-slide-leave-to {
    position: absolute;
    left: -100%;
  }

  &-slide-leave-from {
    position: absolute;
    left: 0;
  }
}
.to-right {
  &-slide-enter-to {
    position: absolute;
    left: 0;
  }

  &-slide-enter-from {
    position: absolute;
    left: -100%;
  }

  &-slide-leave-to {
    position: absolute;
    right: -100%;
  }

  &-slide-leave-from {
    position: absolute;
    right: 0;
  }
}
</style>

<style lang="scss" scoped>
@import "./assets/scss/_variables";

.sidepanel__container {
  background: url(./assets/ae.jpg);
  background-size: 100%;
}

#viewContainer {
  width: 100%;
  min-height: 100vh;
  &:not(.to-left-slide-enter-active):not(.to-left-slide-leave-active):not(.to-right-slide-enter-active):not(.to-right-slide-leave-active) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .box {
    // border-top: none;
    // border-bottom: none;
    // box-shadow: none;
    // border-radius: 0;
    // min-height: 100vh;
  }
}

.pattern {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: linear-gradient(
      30deg,
      $primary 12%,
      transparent 12.5%,
      transparent 87%,
      $primary 87.5%,
      $primary
    ),
    linear-gradient(
      150deg,
      $primary 12%,
      transparent 12.5%,
      transparent 87%,
      $primary 87.5%,
      $primary
    ),
    linear-gradient(
      30deg,
      $primary 12%,
      transparent 12.5%,
      transparent 87%,
      $primary 87.5%,
      $primary
    ),
    linear-gradient(
      150deg,
      $primary 12%,
      transparent 12.5%,
      transparent 87%,
      $primary 87.5%,
      $primary
    ),
    linear-gradient(
      60deg,
      77 25%,
      rgba($primary, 0.77),
      transparent 25.5%,
      transparent 75%,
      rgba($primary, 0.77) 75%,
      rgba($primary, 0.77)
    ),
    linear-gradient(
      60deg,
      rgba($primary, 0.77) 25%,
      transparent 25.5%,
      transparent 75%,
      rgba($primary, 0.77) 75%,
      rgba($primary, 0.77)
    );
  background-size: 70px 123px;
  background-position: 0 0, 0 0, 35px 61px, 35px 61px, 0 0, 35px 61px;
}
</style>

<script>
import Navbar from "./components/Navbar.vue";
import SidePanel from "./components/SidePanel/index.vue";
import { mapGetters, mapState } from "vuex";
import ViewDisputeModal from "@/views/Actions/Disputes/ViewDisputeModal";
import ViewPaymentPromiseModal from "@/views/Actions/PaymentPromises/ViewPaymentPromiseModal";
import ViewPaymentPlanModal from "@/views/Actions/PaymentPlans/ViewPaymentPlanModal";
import NavbarMobile from "@/components/NavbarMobile.vue";

export default {
  data: () => (
      { direction: "none", isMobile: true }),
  components: {
    NavbarMobile,
    ViewPaymentPlanModal,
    ViewPaymentPromiseModal,
    ViewDisputeModal,
    Navbar,
    SidePanel,
  },
  computed: { ...mapGetters(["isLoggedIn"]), ...mapState(["user"]) },
  async mounted() {
    if(process.env.NODE_ENV === 'production') {
      // setTimeout(() => document.querySelector("body").requestFullscreen(), 2000);
      // document
      //     .querySelector("body")
      //     .addEventListener("click", () =>
      //         document.querySelector("body").requestFullscreen()
      //     )
      // ;
    }
    this.$store.state.emitter.$on("viewDispute", (dispute) => {
      this.openViewDisputeModal(dispute);
    });
    this.$store.state.emitter.$on("viewPaymentPromise", (promise) => {
      this.openViewPaymentPromiseModal(promise);
    });
    this.$store.state.emitter.$on("viewPaymentPlan", (plan) => {
      this.openViewPaymentPlanModal(plan);
    });
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    // setTimeout(() => (this.direction = "to-left"), 2000);
    // console.log(await console.request({ message: "test from cmt" }));
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 700 && (
          this.$store.state.me.user.email === 'info@marcocorver.nl'
          || this.$store.state.me.user.email === 'maurice@consultinvest.nl'
          || this.$store.state.me.user.email === 'jim.harders@gmail.com'

      );
    },
    changeRoute({ navItem, index, oldIndex }) {
      this.direction = index < oldIndex ? "to-right" : "to-left";
      this.$nextTick(() => this.$router.push(navItem.path));
    },
    openViewDisputeModal(disputeIdentifier) {
      this.$refs.ViewDisputeModal.open({
        dispute: disputeIdentifier,
      });
    },
    openViewPaymentPromiseModal(promiseIdentifier) {
      this.$refs.ViewPaymentPromiseModal.open({
        promise: promiseIdentifier,
      });
    },
    openViewPaymentPlanModal(planIdentifier) {
      this.$refs.ViewPaymentPlanModal.open({
        plan: planIdentifier,
      });
    },
    displayNavbar(){
      console.log('ss')
      return !(this.isMobile && this.direction !== 'none');
    },
    navigateBack(){
      // this.$router.go(-1);
      this.hasHistory()
          ? this.$router.go(-1)
          : this.$router.push('/')
      setTimeout(() => {
        if(this.$router.currentRoute.value.path === '/' ) {
          this.direction = 'none';
        }
      }, 500);


      //this.$router.push({name: 'Home'})
    },
    hasHistory(){
      return window.history.length > 2
    },

    navigateToHome(){
      this.$router.push('/')
      this.direction = 'none';
      setTimeout(() => {
        window.location.reload(true);
      }, 100)
    }
  },
};
</script>

<style lang="scss" scoped>
.columns {
  background: white;
  height: 100vh;
  .column {
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
@import "./assets/scss/app";
</style>
