import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async getNotes({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/notes`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getNote({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/note`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putNote({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/note`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postUpdateNote({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/note`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async deleteNote({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/note`,
    params: payload,
    headers: headers,
    method: "DELETE",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getFolders({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/folders`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getFolder({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/folder`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putFolder({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/folder`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postUpdateFolder({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/folder`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async deleteFolder({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "note.kit.domain", {
      root: true,
    })}/api/folder`,
    params: payload,
    headers: headers,
    method: "DELETE",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        getNotesStatus: (state) => state.getNotesStatus,
    getNoteStatus: (state) => state.getNoteStatus,
    putNoteStatus: (state) => state.putNoteStatus,
    postUpdateNoteStatus: (state) => state.postUpdateNoteStatus,
    deleteNoteStatus: (state) => state.deleteNoteStatus,
    getFoldersStatus: (state) => state.getFoldersStatus,
    getFolderStatus: (state) => state.getFolderStatus,
    putFolderStatus: (state) => state.putFolderStatus,
    postUpdateFolderStatus: (state) => state.postUpdateFolderStatus,
    deleteFolderStatus: (state) => state.deleteFolderStatus,
  },
};