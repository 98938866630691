<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card" :style="{
        width: '75vw',
      }">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ t(`${list.name} actions`) }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button class="delete" aria-label="close"  v-if="!isLoading" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-4">
          <Table
              :is-paginated="false"
              :loading="list.loading"
              :pageData="{}"
              :headers="headers"
              :rows="list.rows"
              :breakingPoint="400"
          />
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span
              class="card-footer-item has-text-danger is-clickable"
              @click="close"
          >
            {{ t('Cancel') }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
import Table from "@/components/Dynamic/Table";
import CompleteAction from "@/components/Forms/Input/CompleteAction"
export default {
  name: 'ListModal',
  components: {Table},
  data() {
    return {
      isOpen: false,
      isLoading: false,

      owner_identifier: null,
      list: {
        name: '',
        loading: false,
        pageData: {
          currentPage: 1,
          lastPage: 1,
        },
        rows: [],
        definitions: {},
      },
      headers: [
        {
          title: "Customer id",
          name: "company_id",

        },
        {
          title: "description",
          name: "description",
        },
        {
          title: "scheduled at",
          name: "scheduled_at",
        },
        {
          title: "Complete",
          component: {
            component: CompleteAction,
            bind: {},
          }
        },
      ],

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    }
  },
  computed: {
  },
  methods: {
    async open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.list.name = values.list.name;
      this.emptySelectedRows();

      this.list.loading = true;
      try {
        let data = await this.$store.dispatch("dataviewKit/getViewData", {
          view: `cm_incaze_nl_${this.list.name.toLowerCase()}_actions`,
          paginated: 0,
          parameters: {
            owner_identifier:
                (
                    this.$store.state.me.activeSeller ??
                    this.$store.state.me.user.companies[0] ?? { identifier: "" }
                ).identifier,
          },
          filters: {
            company_id: this.$route.params.company_id ?? undefined,
          }
        });




        if (!data.success) {
          throw data;
        }

        this.list.rows = data.data;

      } catch (error) {
        console.log(error);
      }



      this.isOpen = true;
    },
    close() {
      this.$emit('close');
      this.isOpen = false;
    },
    emptySelectedRows() {
      this.list.rows = this.list.rows.map((row) => ({
        ...row,
        selected: false,
      }));
      this.list.selectedRows = [];
      this.$forceUpdate();
    },
    selectRow(gRow = {}) {
      if (!gRow.identifier) return true;
      if (gRow.selected) {
        this.list.selectedRows = this.list.selectedRows.filter(
            (row) => row.identifier !== gRow.identifier
        );
        this.list.rows.find(
            (row) => row.identifier === gRow.identifier
        ).selected = false;
      } else {
        this.list.selectedRows.push(gRow);

        this.list.rows.find(
            (row) => row.identifier === gRow.identifier
        ).selected = true;
      }
    },
    async send() {

      let payload = {};

      try {
        let data = await this.$store.dispatch("flowKit/postCmtHandleSendReminders", {
          reminders: payload,
        });

        this.authorizingDomain = '';

        if (!data.success) {
          throw data;
        }

        this.close();
        // todo: add a popup

      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
