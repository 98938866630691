<template>
  <div id="CreateNoteModal">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ plan.name }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button
            class="delete"
            aria-label="close"
            v-if="!isLoading"
            @click="close"
          ></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-3 pt-3">
          <p>
            <strong>{{ t("Debtor") }}</strong
            ><br /><router-link :to="`/customer/${plan.owner_buyer}`">{{
              plan.owner_buyer ?? " - "
            }}</router-link>
          </p>
          <p>
            <strong>{{ t("Description") }}</strong
            ><br />{{ plan.description ?? " - " }}
          </p>
          <strong>{{ t("Scheduled Payments") }}</strong>
          <table class="custom__table mb-0">
            <thead>
              <tr>
                <th>
                  {{ t("Payment Date") }}
                </th>
                <th class="has-text-right">
                  {{ t("Payment Amount") }}
                </th>
                <th class="has-text-right">
                  {{ t("Mark as paid") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(payment, index) in plan.payments" :key="payment">
                <td>{{ payment.scheduled_at }}</td>
                <td class="has-text-right">
                  {{ getFormattedNumber(payment.amount) }}
                </td>
                <td>
                  <button class="button is-dafault is-small is-pulled-right has-text-weight-bold" @click="pay(payment.scheduled_payment, index)"
                          :disabled="payment.paid || isLoadingPayment === payment.scheduled_payment"
                          :class="{
                    'is-primary': payment.paid,
                  }">
                    <span class="icon">
                      <span :class="['fa', {
                        'fa-check': payment.paid,
                        'fa-cube' : !payment.paid
                      }]"></span>
                    </span>
                    <span v-if="payment.paid">{{ t('Paid') }}</span>
                    <span v-if="!payment.paid">{{ t('Pay') }}</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <strong>{{ t("Invoices") }}</strong>
          <table class="custom__table mb-0">
            <thead>
              <tr>
                <th>
                  {{ t("Invoice Id") }}
                </th>
                <th class="has-text-right">
                  {{ t("Original amount") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in plan.items" :key="item">
                <td>{{ item.invoice }}</td>
                <td class="has-text-right">
                  {{ getFormattedNumber(item.invoice_amount) }}
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0 is-clipped">
          <span
            class="card-footer-item has-text-danger is-clickable"
            :disabled="isLoading"
            @click="close"
          >
            {{ t("Cancel") }}
          </span>
          <span
            class="card-footer-item has-text-link"
            :class="[
              isLoading || plan?.resolved
                ? 'has-background-grey-lighter'
                : 'is-clickable',
            ]"
            @click="
              () => (isLoading || plan?.resolved ? '' : markPaymentPlanAsPaid())
            "
          >
            {{
              !!plan?.resolved ? t("Already resolved") : t("Mark as resolved")
            }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "ViewPaymentPlanModal",
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isLoadingPayment: false,
      owner_identifier: null,
      payment_plan_currency: null,
      plan: {},
      plan_identifier: null,
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  computed: {},
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.plan_identifier = values.plan;
      this.get();
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit("close");
    },
    async get() {
      const { plan_identifier } = this;
      try {
        this.isLoading = true;
        let data = await this.$store.dispatch("financialKit/getPaymentPlan", {
          plan: plan_identifier,
        });

        // todo:
        this.plan = data.plan;
        this.isLoading = false;
        if (!data.success) {
          throw data;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async pay(paymentId, paymentIndex) {

      try {
        this.isLoadingPayment = paymentId;
        let data = await this.$store.dispatch("financialKit/postUpdateScheduledPayment", {
          scheduled_payment: paymentId,
          paid: 1,
          processed: 1,
        });

        this.isLoadingPayment = false;
        if (!data.success) {
          throw data;
        }

        this.plan.payments[paymentIndex].paid = 1;
        this.plan.payments[paymentIndex].processed = 1;

      } catch (error) {
        console.log(error);
        this.isLoadingPayment = paymentId;
      }
    },
    async markPaymentPlanAsPaid() {
      const { plan_identifier } = this;
      try {
        this.isLoading = true;
        let data = await this.$store.dispatch(
          "financialKit/postUpdatePaymentPlan",
          {
            plan: plan_identifier,
            resolved: 1,
          }
        );

        // todo:
        this.plan = data.plan;

        this.isLoading = false;
        this.$store.state.emitter.$emit('reloadCustomerInvoices');

        if (!data.success) {
          throw data;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number);
    },
  },
};
</script>
