<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div
        class="modal-card"
        :style="{
          width: '85vw',
        }"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ t("Reminders") }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button
            class="delete"
            aria-label="close"
            v-if="!isLoading"
            @click="close"
          ></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-4">
          <Table
            :rowClickHandler="selectRow"
            :is-paginated="false"
            :loading="reminders.loading"
            :pageData="{}"
            :headers="headers"
            :rows="reminders.rows"
            :breakingPoint="500"
          />
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span
            class="card-footer-item has-text-danger is-clickable"
            @click="close"
          >
            {{ t("Cancel") }}
          </span>
          <span
            class="card-footer-item has-text-danger is-clickable"
            @click="selectAll"
          >
            {{
              reminders.selectedRows.length === 0
                ? t("Select All")
                : t("Unselect All")
            }}
          </span>
          <span
            v-if="reminders.selectedRows.length > 0"
            class="card-footer-item has-text-info is-clickable"
            @click="send"
          >
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
            <span v-else>
              {{ t("Send Selected Reminders") }} ({{
                reminders.selectedRows.length
              }})
            </span>
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
import { markRaw } from "vue";

import Table from "@/components/Dynamic/Table";
import ReminderSelect from "@/components/Forms/Input/ReminderSelect";
export default {
  name: "RemindersModal",
  components: { Table },
  data() {
    return {
      isOpen: false,
      isLoading: false,

      owner_identifier: null,
      reminders: {
        loading: false,
        pageData: {
          currentPage: 1,
          lastPage: 1
        },
        headers: this.$store.getters["TableHeaders/getHeaders"](
          "cm_incaze_nl_reminders"
        ),
        rows: [],
        selectedRows: [],
        definitions: {}
      },
      headers: [
        {
          title: "Customer id",
          name: "company_id", anchor(ctx, row) {
            let routeInformation = ctx.$router.resolve({name: 'Customer', params: {id: row.company_id, ...row},});
            window.open(routeInformation.href, '_blank');
          },
        },
        {
          title: "Customer name",
          name: "company_name"
        },
        {
          title: "open amount",
          name: "open_amount",
          transformer: "getFormattedNumber",
          align: "right"
        },
        {
          title: "overdue amount",
          name: "overdue_amount",
          transformer: "getFormattedNumber",
          align: "right"
        },
        {
          title: "oldest overdue",
          name: "oldest_overdue_invoice",
          transformer: "getFormattedDate",
          align: "left"
        },
        {
          title: "last reminder",
          name: "last_reminder_date"
        },
        {
          title: "last note",
          name: "last_note"
        },
        {
          title: "Reminder",
          name: "overdue_amount",
          component: {
            component: markRaw(ReminderSelect),
            bind: {},
            on: {
              changed: (row, value) => {
                this.reminders.definitions[row.company_id] = value;
              }
            }
          }
        }
      ],

      t: this.$store.getters["Translations/getTranslations"](this.$options.name)
    };
  },
  computed: {},
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.reminders.rows = values.reminders;
      this.emptySelectedRows();

      this.isOpen = true;
    },
    close() {
      this.$emit("close");
      this.isOpen = false;
    },
    emptySelectedRows() {
      this.reminders.rows = this.reminders.rows.map(row => ({
        ...row,
        selected: false
      }));
      this.reminders.selectedRows = [];
      this.$forceUpdate();
    },
    selectRow(gRow = {}) {
      if (!gRow.company_id) return true;
      if (gRow.selected) {
        this.reminders.selectedRows = this.reminders.selectedRows.filter(
          row => row.company_id !== gRow.company_id
        );
        this.reminders.rows.find(
          row => row.company_id === gRow.company_id
        ).selected = false;
      } else {
        this.reminders.selectedRows.push(gRow);

        this.reminders.rows.find(
          row => row.company_id === gRow.company_id
        ).selected = true;
      }
    },
    selectAll() {
      if (this.reminders.selectedRows.length === 0) {
        this.reminders.rows = this.reminders.rows.map(a => ({
          ...a,
          selected: true
        }));
        this.reminders.selectedRows = this.reminders.rows;
        this.$forceUpdate();
      } else {
        this.emptySelectedRows();
      }
    },
    async send() {
      if (!this.isLoading) {
        this.isLoading = true;
        let payload = {};
        payload[this.owner_identifier] = [];
        for (let i in this.reminders.selectedRows) {
          payload[this.owner_identifier].push({
            company: this.reminders.selectedRows[i].company_id,
            reminder: this.reminders.definitions[
              this.reminders.selectedRows[i].company_id
            ]
          });
        }

        try {
          let data = await this.$store.dispatch(
            "flowKit/postCmtHandleSendReminders",
            {
              reminders: payload
            }
          );

          this.authorizingDomain = "";

          if (!data.success) {
            throw data;
          }

          this.close();
          // todo: add a popup
        } catch (error) {
          console.log(error);
        }
        this.isLoading = false;
      }
    }
  }
};
</script>
