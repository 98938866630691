import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;

import idKit from "./IOKits/id.kit";

export default {
  modules: { idKit },
  state: () => ({
    status: "",
    token: "",
    user: null,
    activeSeller: null,
  }),
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user }) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = null;
    },
    activeSeller(state, seller) {
      state.activeSeller = seller;
    },
  },
  actions: {
    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        dispatch("idKit/postAuth", user)
          .then((data) => {
            const { token, token_prefix } = data;
            axios.defaults.headers.common[
              "Authorization"
            ] = `${token_prefix} ${token}`;

            dispatch("idKit/getMe", { withCompanies: true, withStakeholdingOwnedCompanies: true })
              .then((data) => {
                console.log(data);
                data.companies = data.companies.concat(data.stakeholder_companies);
                commit("auth_success", {
                  token: `${token_prefix} ${token}`,
                  user: data,
                });
              })
              .catch((data) => console.error(data));

            resolve(data);
          })
          .catch((data) => {
            commit("auth_error");
            reject(data);
          });
      });
    },

    setActiveSeller({ commit }, seller) {
      commit("activeSeller", seller);
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    me: (state) => state.user,
  },
};
