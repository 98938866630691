<template>
  <div id="CreateNoteModal">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ dispute.name }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button
            class="delete"
            aria-label="close"
            v-if="!isLoading"
            @click="close"
          ></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-3 pt-3">
          <p>
            <strong>{{ t("Debtor") }}</strong
            ><br /><router-link :to="`/customer/${dispute.owner_buyer}`">{{
              dispute.owner_buyer ?? " - "
            }}</router-link>
          </p>
          <p>
            <strong>{{ t("Reason") }}</strong
            ><br />{{ dispute.basis ?? " - " }}
          </p>
          <p>
            <strong>{{ t("Description") }}</strong
            ><br />{{ dispute.description ?? " - " }}
          </p>
          <p>
            <strong>{{ t("Amount Disputed") }}</strong
            ><br />{{ getFormattedNumber(dispute.disputed_amount) }}
          </p>
          <table class="custom__table mb-0">
            <thead>
              <th>
                {{ t("Invoice Id") }}
              </th>
              <th class="has-text-right">
                {{ t("Original amount") }}
              </th>
              <th class="has-text-right">
                {{ t("dispute amount") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="item in dispute.items" :key="item">
                <td>{{ item.invoice }}</td>
                <td class="has-text-right">
                  {{ getFormattedNumber(item.invoice_amount) }}
                </td>
                <td class="has-text-right">
                  {{
                    getFormattedNumber(dispute.disputed_amounts[item.invoice])
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0 is-clipped">
          <span
            class="card-footer-item has-text-danger is-clickable"
            :disabled="isLoading"
            @click="close"
          >
            {{ t("Cancel") }}
          </span>
          <span
            class="card-footer-item has-text-link"
            :class="[
              isLoading || dispute?.resolved
                ? 'has-background-grey-lighter'
                : 'is-clickable',
            ]"
            @click="
              () => (isLoading || dispute?.resolved ? '' : markDisputeAsPaid())
            "
          >
            {{
              !!dispute?.resolved
                ? t("Already resolved")
                : t("Mark as resolved")
            }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "ViewDisputeModal",
  data() {
    return {
      isOpen: false,
      isLoading: false,
      owner_identifier: null,
      dispute: {},
      dispute_identifier: null,
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  computed: {},
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.dispute_identifier = values.dispute;
      this.get();
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit("close");
    },
    async get() {
      const { dispute_identifier } = this;
      try {
        this.isLoading = true;
        let data = await this.$store.dispatch("financialKit/getDispute", {
          dispute: dispute_identifier,
        });

        // todo:
        this.dispute = data.dispute;
        this.isLoading = false;
        if (!data.success) {
          throw data;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async markDisputeAsPaid() {
      const { dispute_identifier } = this;

      try {
        this.isLoading = true;
        let data = await this.$store.dispatch(
          "financialKit/postUpdateDispute",
          {
            dispute: dispute_identifier,
            resolved: 1,
          }
        );

        // todo:
        this.dispute = data.dispute;
        this.isLoading = false;

        this.$store.state.emitter.$emit('reloadCustomerInvoices');

        if (!data.success) {
          throw data;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number);
    },
  },
};
</script>
