import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async getPaymentPlanBases({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-plan/bases`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getPaymentPromiseBases({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-promise/bases`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getDisputeBases({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/dispute/bases`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getPaymentPlan({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-plan`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getPaymentPromise({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-promise`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getDispute({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/dispute`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getPaymentPlans({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-plans`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getPaymentPromises({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-promises`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getDisputes({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/disputes`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putPaymentPlan({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-plan`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putPaymentPromise({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-promise`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putDispute({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/dispute`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postUpdatePaymentPlan({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-plan`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postUpdatePaymentPromise({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payment-promise`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postUpdateDispute({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/dispute`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
async postUpdateScheduledPayment({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/payments/scheduled/payment`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putCollectionCase({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/collection/case`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

    async postUpdateCollectionCase({ dispatch }, payload = {}, headers = {}) {
      let response = await axios({
        url: `${await dispatch("getEnv", "financial.kit.domain", {
          root: true,
        })}/api/collection/case`,
        data: payload,
        headers: headers,
        method: "POST",
      });
      if (response.data.success) {
        return response.data;
      } else {
        throw response.data;
      }
    },

async postCalculateCollectionCaseInterests({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "financial.kit.domain", {
      root: true,
    })}/api/collection/case/interests`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        getPaymentPlanBasesStatus: (state) => state.getPaymentPlanBasesStatus,
    getPaymentPromiseBasesStatus: (state) => state.getPaymentPromiseBasesStatus,
    getDisputeBasesStatus: (state) => state.getDisputeBasesStatus,
    getPaymentPlanStatus: (state) => state.getPaymentPlanStatus,
    getPaymentPromiseStatus: (state) => state.getPaymentPromiseStatus,
    getDisputeStatus: (state) => state.getDisputeStatus,
    getPaymentPlansStatus: (state) => state.getPaymentPlansStatus,
    getPaymentPromisesStatus: (state) => state.getPaymentPromisesStatus,
    getDisputesStatus: (state) => state.getDisputesStatus,
    putPaymentPlanStatus: (state) => state.putPaymentPlanStatus,
    putPaymentPromiseStatus: (state) => state.putPaymentPromiseStatus,
    putDisputeStatus: (state) => state.putDisputeStatus,
    postUpdatePaymentPlanStatus: (state) => state.postUpdatePaymentPlanStatus,
    postUpdatePaymentPromiseStatus: (state) => state.postUpdatePaymentPromiseStatus,
    postUpdateDisputeStatus: (state) => state.postUpdateDisputeStatus,
    putCollectionCaseStatus: (state) => state.putCollectionCaseStatus,
  },
};