<template>
  <div id="CreateNoteModal">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ promise.name }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button
            class="delete"
            aria-label="close"
            v-if="!isLoading"
            @click="close"
          ></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-3 pt-3">
          <p>
            <strong>{{ t("Debtor") }}</strong
            ><br /><router-link :to="`/customer/${promise.owner_buyer}`">{{
              promise.owner_buyer ?? " - "
            }}</router-link>
          </p>
          <p>
            <strong>{{ t("Description") }}</strong
            ><br />{{ promise.description ?? " - " }}
          </p>
          <p>
            <strong>{{ t("Promised Amount") }}</strong
            ><br />{{ getFormattedNumber(promise.promise_amount) }}
          </p>
          <p>
            <strong>{{ t("Promised At") }}</strong
            ><br />{{ promise.payment?.scheduled_at }}
          </p>
          <table class="custom__table mb-0">
            <thead>
              <tr>
                <th>
                  {{ t("Invoice Id") }}
                </th>
                <th class="has-text-right">
                  {{ t("Original amount") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in promise.items" :key="item">
                <td>{{ item.invoice }}</td>
                <td class="has-text-right">
                  {{ getFormattedNumber(item.invoice_amount) }}
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0 is-clipped">
          <span
            class="card-footer-item has-text-danger is-clickable"
            :disabled="isLoading"
            @click="close"
          >
            {{ t("Cancel") }}
          </span>
          <span
            class="card-footer-item has-text-link"
            :class="[
              isLoading || promise?.resolved
                ? 'has-background-grey-lighter'
                : 'is-clickable',
            ]"
            @click="
              () =>
                isLoading || promise?.resolved ? '' : markPaymentPromiseAsPaid()
            "
          >
            {{
              !!promise?.resolved
                ? t("Already resolved")
                : t("Mark as resolved")
            }}
          </span>
        </footer>
        <!-- <pre>{{ !!promise.payment.paid }}</pre> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "ViewPaymentpromiseModal",
  data() {
    return {
      isOpen: false,
      isLoading: false,
      owner_identifier: null,
      promise: {},
      promise_identifier: null,
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  computed: {},
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.promise_identifier = values.promise;
      this.get();
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit("close");
    },
    async get() {
      const { promise_identifier } = this;
      try {
        this.isLoading = true;
        let data = await this.$store.dispatch(
          "financialKit/getPaymentPromise",
          {
            promise: promise_identifier,
          }
        );

        // todo:
        this.promise = data.promise;

        this.isLoading = false;

        if (!data.success) {
          throw data;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },

    async markPaymentPromiseAsPaid() {
      const { promise_identifier } = this;
      try {
        this.isLoading = true;
        let data = await this.$store.dispatch(
          "financialKit/postUpdatePaymentPromise",
          {
            promise: promise_identifier,
            resolved: 1,
          }
        );

        this.promise = data.promise;

        this.isLoading = false;
        this.$store.state.emitter.$emit('reloadCustomerInvoices');

        if (!data.success) {
          throw data;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },

    getFormattedNumber(number) {
      return this.$store.getters[`Formatting/getFormattedNumber`](number);
    },
  },
};
</script>
