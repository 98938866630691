export default {
    namespaced: true,
    state: {
        defaultReminderFlows: [{
            "identifier": "first.reminder",
            "name": "First Reminder",
            "days": 10,
            "position": 0,
            "template": "flow.kit.cm.incaze.nl.first.reminder"
        },{
            "identifier": "second.reminder",
            "name": "Second reminder",
            "days": 20,
            "position": 0,
            "template": "flow.kit.cm.incaze.nl.second.reminder"
        },{
            "identifier": "final.demand",
            "name": "Final demand",
            "days": 4,
            "position": 0,
            "template": "flow.kit.cm.incaze.nl.final.demand"
        },{
            "identifier": "summons",
            "name": "Summons",
            "days": 10,
            "position": 0,
            "template": "flow.kit.cm.incaze.nl.summons"
        },{
            "identifier": "collection.case",
            "name": "Collection Case",
            "days": 2,
            "position": 0,
            "template": "flow.kit.cm.incaze.nl.collection.case"
        }],
    },
    mutations: {},
    actions: {},
    getters: {
        getDefaultReminderFlows: (state) => () => {
            return state.defaultReminderFlows;
        }
    },
};
