import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async putQuery({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/query`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getQuery({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/query`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getQueries({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/queries`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postQuery({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/query`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async deleteQuery({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/query`,
    params: payload,
    headers: headers,
    method: "DELETE",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getQueryData({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/query/data`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putView({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/view`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getView({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/view`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getViews({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/views`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postView({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/view`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async deleteView({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/view`,
    params: payload,
    headers: headers,
    method: "DELETE",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getViewData({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "dataview.kit.domain", {
      root: true,
    })}/api/view/data`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
    async getCustomerData({ dispatch }, payload = {}, headers = {}) {
      let response = await axios({
        url: `${await dispatch("getEnv", "datacustomers.kit.domain", {
          root: true,
        })}/api/customers/data`,
        params: payload,
        headers: headers,
        method: "GET",
      });
      if (response.data.success) {
        return response.data;
      } else {
        throw response.data;
      }
    },
  },
  getters: {
        putQueryStatus: (state) => state.putQueryStatus,
    getQueryStatus: (state) => state.getQueryStatus,
    getQueriesStatus: (state) => state.getQueriesStatus,
    postQueryStatus: (state) => state.postQueryStatus,
    deleteQueryStatus: (state) => state.deleteQueryStatus,
    getQueryDataStatus: (state) => state.getQueryDataStatus,
    putViewStatus: (state) => state.putViewStatus,
    getViewStatus: (state) => state.getViewStatus,
    getViewsStatus: (state) => state.getViewsStatus,
    postViewStatus: (state) => state.postViewStatus,
    deleteViewStatus: (state) => state.deleteViewStatus,
    getViewDataStatus: (state) => state.getViewDataStatus,
  },
};