export default {
    namespaced: true,
    state: {

    },
    mutations: {},
    actions: {

    },
    getters: {
        getFormattedNumber: () => (number, currency = 'EUR') => {
            try {
                return new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: currency,
                }).format(number);
            } catch(error) { return number; }
        },
        getFormattedDateTime: () => (date) => {
            date = new Date(Date.parse(date));
            return [
                [
                    (date.getFullYear()),
                    (date.getMonth()+1).toString().padStart(2, '0'),
                    (date.getDate()).toString().padStart(2, '0'),
                ].join('-'),
                [
                    (date.getHours()).toString().padStart(2, '0'),
                    (date.getMinutes()).toString().padStart(2, '0'),
                    // (date.getSeconds()).toString().padStart(2, '0'),
                ].join(':'),
            ].join(' ');

        },
        getFormattedDate: () => (date) => {
            date = new Date(Date.parse(date));
            return [
                (date.getFullYear()),
                (date.getMonth()+1).toString().padStart(2, '0'),
                (date.getDate()).toString().padStart(2, '0'),
            ].join('-');
        },
        getTodayFormattedDate: () => () => {
            let date = new Date();
            return [
                (date.getFullYear()),
                (date.getMonth()+1).toString().padStart(2, '0'),
                (date.getDate()).toString().padStart(2, '0'),
            ].join('-');
        },
    },
};
