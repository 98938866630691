import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async postUploadImportFile({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "import.kit.domain", {
      root: true,
    })}/api/upload/import/file`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postSaveMappingImport({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "import.kit.domain", {
      root: true,
    })}/api/save/mapping/import`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        postUploadImportFileStatus: (state) => state.postUploadImportFileStatus,
    postSaveMappingImportStatus: (state) => state.postSaveMappingImportStatus,
  },
};