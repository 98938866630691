let env = import(`./env.${process.env.NODE_ENV}.json`);

export default {
  state: () => ({
    apiUrls: {},
  }),
  mutations: {
    // add_url(state, { key, url }) {
    //   state.apiUrls[key] = url;
    // },
  },
  actions: {
    getEnv: {
      root: true,
      async handler(state, key) {
        let domain = (await env).default[key];
        if(/^https?/i.test(domain)) { return domain; }
        return `${window.location.protocol}//${domain}`;
      },
    },
  },
  getters: {
    getApiUrlById: async (state) => async (api) => {
      return await state.apiUrls[api];
    },
  },
};
