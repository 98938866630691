import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async postBewittHandleEvent({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "flow.kit.domain", {
      root: true,
    })}/api/bewitt/handle/event`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postBewittHandlePaymentCallback({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "flow.kit.domain", {
      root: true,
    })}/api/bewitt/handle/payment`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postCmtHandleSendReminders({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "flow.kit.domain", {
      root: true,
    })}/api/cmt/handle/send/reminders`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postCmtReminderFlows({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "flow.kit.domain", {
      root: true,
    })}/api/cmt/reminder/flows`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postCmtAuthorizedDomains({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "flow.kit.domain", {
      root: true,
    })}/api/cmt/authorized/domains`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getCmtReminderFlows({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "flow.kit.domain", {
      root: true,
    })}/api/cmt/reminder/flows`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getCmtAvailableReminders({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "flow.kit.domain", {
      root: true,
    })}/api/cmt/available/reminders`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getCmtAuthorizedDomains({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "flow.kit.domain", {
      root: true,
    })}/api/cmt/authorized/domains`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        postBewittHandleEventStatus: (state) => state.postBewittHandleEventStatus,
    postBewittHandlePaymentCallbackStatus: (state) => state.postBewittHandlePaymentCallbackStatus,
    postCmtHandleSendRemindersStatus: (state) => state.postCmtHandleSendRemindersStatus,
    postCmtReminderFlowsStatus: (state) => state.postCmtReminderFlowsStatus,
    postCmtAuthorizedDomainsStatus: (state) => state.postCmtAuthorizedDomainsStatus,
    getCmtReminderFlowsStatus: (state) => state.getCmtReminderFlowsStatus,
    getCmtAvailableRemindersStatus: (state) => state.getCmtAvailableRemindersStatus,
    getCmtAuthorizedDomainsStatus: (state) => state.getCmtAuthorizedDomainsStatus,
  },
};