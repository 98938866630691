import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dash",
    name: "Dash",
    component: () => import("../views/Dash"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("../views/EmptyRouterView.vue"),
    children: [
      {
        path: "",
        name: "Customers",
        component: () => import("../views/Customers"),
      },
      {
        path: "/customer/:parent_identifier/:company_id",
        name: "Customer",
        component: () => import("../views/Customers/SingleView/"),
      },
      {
        path: "/customer/:parent_identifier/:company_id/invoice",
        name: "InvoiceCustomer",
        component: () => import("../views/Invoices/Invoicing.vue"),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/reports",
    name: "Reports",
    component: () => import("../views/EmptyRouterView"),
    children: [
      {
        path: "",
        name: "Reports",
        component: () => import("../views/Reports"),
      },
      {
        path: "/report/:report_id",
        name: "Report",
        component: () => import("../views/Reports/SingleView/"),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next({ path: from.path });
      } else {
        next();
      }
    },
  },

  {
    path: "/new_customer",
    name: "NewCustomer",
    component: () => import("../views/Customers/SingleView/NewCustomer.vue"),

  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
