<template>
  <div class="SellerDropdown">
    <div
      class="dropdown is-block"
      :class="{ 'is-active': true }"
      v-click-away="clickAway"
    >
      <div class="dropdown-trigger is-fullwidth">
        <button
          class="button is-fullwidth"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          @click="isActive = !isActive"
        >
          <span>{{ $store.state.Translations.currentLang }}</span>
          <span class="icon is-small">
            <i
              :class="['fas', `fa-angle-${isActive ? 'up' : 'down'}`]"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </div>

      <div
        class="dropdown-menu"
        :class="{ 'is-open': isActive }"
        id="dropdown-menu"
        role="menu"
      >
        <div class="custom__container">
          <div class="dropdown-content">
            <span
              class="dropdown-item is-size-7"
              :class="{
                'is-clickable': currentLang !== lang,
              }"
              v-for="lang in Object.keys(translations ?? {})"
              :key="`${lang[0]}${Math.random()}`"
              @click="changeCurrentLang(lang)"
            >
              {{ lang }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.dropdown {
  .dropdown-trigger {
    min-width: 100%;
    .button {
      font-weight: $weight-semibold;
      border: none;
      background: #00000036;
      backdrop-filter: blur(2px);
      color: $white-ter;
    }
  }
  .dropdown-menu {
    min-width: 100%;
    pointer-events: none;

    &.is-open {
      .custom__container {
        .dropdown-content {
          transform: translateY(0%);
        }
      }
    }

    .custom__container {
      height: 400px;
      pointer-events: none;
      overflow: hidden;
      position: relative;
      .dropdown-content {
        pointer-events: auto;
        transition: transform 200ms;
        position: absolute;
        width: 100%;
        transform: translateY(-100%);
      }
    }

    .dropdown-content,
    .button {
      font-weight: $weight-semibold;
      border: none;
      background: #00000036;
      backdrop-filter: blur(2px);
      color: $white-ter;
    }
    .dropdown-item {
      color: $white-ter;
    }
  }
}

@keyframes collapse {
  0% {
    height: 0;
  }
}
</style>

<script>
import { mapState } from "vuex";

import { mixin as VueClickAway } from "vue3-click-away";

export default {
  name: "TranslationsDropDown",
  mixins: [VueClickAway],
  data() {
    return {
      isActive: false,
      t: typeof this.$store.getters["Translations/getTranslations"](
        this.$options.name
      )
    };
  },
  computed: {
    ...mapState("Translations", ["translations", "currentLang"])
  },

  created() {
    console.log(this.translations);
  },

  methods: {
    clickAway() {
      this.isActive = false;
    },
    changeCurrentLang(lang) {
      if (this.currentLang !== lang.identifier) {
        this.isActive = false;
        this.$store.commit("Translations/setCurrentLang", lang);
      }
    }
  }
};
</script>
