import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async getMe({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "id.kit.domain", {
      root: true,
    })}/api/me`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postAuth({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "id.kit.domain", {
      root: true,
    })}/api/auth`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postRegister({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "id.kit.domain", {
      root: true,
    })}/api/register`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getConfirmCredentials({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "id.kit.domain", {
      root: true,
    })}/api/confirm/{token}`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putContactInformation({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "id.kit.domain", {
      root: true,
    })}/api/contact/information`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putContactInformationSettings({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "id.kit.domain", {
      root: true,
    })}/api/contact/information/settings`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putContactInformationSocialNetworks({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "id.kit.domain", {
      root: true,
    })}/api/contact/information/social-networks`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getListOfUsers({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "id.kit.domain", {
      root: true,
    })}/api/list/of/users`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        getMeStatus: (state) => state.getMeStatus,
    postAuthStatus: (state) => state.postAuthStatus,
    postRegisterStatus: (state) => state.postRegisterStatus,
    getConfirmCredentialsStatus: (state) => state.getConfirmCredentialsStatus,
    putContactInformationStatus: (state) => state.putContactInformationStatus,
    putContactInformationSettingsStatus: (state) => state.putContactInformationSettingsStatus,
    putContactInformationSocialNetworksStatus: (state) => state.putContactInformationSocialNetworksStatus,
    getListOfUsersStatus: (state) => state.getListOfUsersStatus,
  },
};