import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async getToday({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/today`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getScheduled({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/scheduled`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getFlagged({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/flagged`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getAll({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/all`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getLists({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/lists`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getListsMetrics({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/lists/metrics`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getListItems({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/list/items`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putListCreate({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/list/create`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putListDelete({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/list/delete`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putListItemCreate({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/list/item/create`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postListItemUpdate({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/list/item/update`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postItemSetPriority({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/item/set/priority`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postItemSetScheduled({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/item/set/scheduled`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postItemSetFlagged({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/item/set/flagged`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postItemSetCompleted({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "todo.kit.domain", {
      root: true,
    })}/api/item/set/completed`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        getTodayStatus: (state) => state.getTodayStatus,
    getScheduledStatus: (state) => state.getScheduledStatus,
    getFlaggedStatus: (state) => state.getFlaggedStatus,
    getAllStatus: (state) => state.getAllStatus,
    getListsStatus: (state) => state.getListsStatus,
    getListsMetricsStatus: (state) => state.getListsMetricsStatus,
    getListItemsStatus: (state) => state.getListItemsStatus,
    putListCreateStatus: (state) => state.putListCreateStatus,
    putListDeleteStatus: (state) => state.putListDeleteStatus,
    putListItemCreateStatus: (state) => state.putListItemCreateStatus,
    postListItemUpdateStatus: (state) => state.postListItemUpdateStatus,
    postItemSetPriorityStatus: (state) => state.postItemSetPriorityStatus,
    postItemSetScheduledStatus: (state) => state.postItemSetScheduledStatus,
    postItemSetFlaggedStatus: (state) => state.postItemSetFlaggedStatus,
    postItemSetCompletedStatus: (state) => state.postItemSetCompletedStatus,
  },
};