<template>

  <div class="complete-action">
    <div class="field">
      <div class="control">
        <button class="button is-small is-pulled-right has-text-weight-bold"
                :class="{
                  'is-primary': !(Number(row.is_completed) === 1 || is_completed),
                  'is-success': Number(row.is_completed) === 1 || is_completed,
                }"
                :disabled="loaders.completing || Number(row.is_completed) === 1 || is_completed"
                @click="completeAction">
          <span>{{ (Number(row.is_completed) === 1 || is_completed) ? t('Completed') : t('Complete') }}</span>
          <span class="icon">
            <span :class="['fa', {
              'fa-check': !loaders.completing,
              'fa-spin fa-spinner': loaders.completing
            }]"></span>
          </span>
        </button>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'CompleteAction',
  props: {
    row: {
      required: true,
    },
  },
  data() {
    return {
      loaders: {
        completing: false,
      },
      is_completed: false,
      currentFlow:null,

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  watch: {
    currentFlow(value) {
      this.$emit('changed', this.row, value);
    }
  },
  mounted() {
    this.currentFlow = this.row.next_reminder;
  },
  methods: {
    async completeAction() {
      if(!this.loaders.completing) {
        this.loaders.completing = true;

        try {
          let data = await this.$store.dispatch(
              "todoKit/postItemSetCompleted",
              {
                item: this.row.identifier,
                completed: 1,

              }
          );

          this.$store.state.emitter.$emit('reloadActionsListFrame');

          if (!data.success) {
            throw data;
          }
          this.loaders.completing = false;
          this.is_completed = true;
        } catch (error) {
          console.log(error);
          this.loaders.completing = false;
        }
      }
    },
  }

}
</script>