import { createApp } from "vue"
import App from "./App.vue"
import store from "./store"

import router from "./router"

import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

import VueAxios from "vue-axios"
import axios from "axios"


if (store.state.me.token) {
  axios.defaults.headers.common["Authorization"] =
    store.state.me.token
}

// console.request = async ({ message }) => {
//   var options = {
//     method: "POST",
//     url: "http://0.0.0.0:8083/rest/hello-world/",
//     headers: { "Content-Type": "application/json" },
//     data: { appName: "test", message, reply: true },
//   };
//   try {
//     const response = await axios.request(options);
//     return response;
//   } catch (e) {
//     console.error(e);
//   }
// };

createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(VueAxios, axios)
  .mount("#app")
