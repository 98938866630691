<template>
  <div id="navbar">
    <div class="tabs mb-4 is-fullwidth mb-3 is-size-6">
      <ul>
        <li
          v-for="(navItem, index) in navItems"
          :key="navItem.icon"
          :class="{
            'is-active': $route.matched.some(
              (route) => route.path === navItem.path
            ),
          }"
          class="is-light"
        >
          <a
            @click.prevent="
              $emit('routeClick', {
                navItem,
                index,
                oldIndex: navItems.findIndex((nI) =>
                  $route.matched.some((route) => route.path === nI.path)
                ),
              })
            "
          >
            <span class="icon is-small"
              ><i :class="['fas', navItem.icon]"></i
            ></span>
            <span class="is-hidden-mobile">{{ t(navItem.name) }}</span>
          </a>
        </li>
        <li>
          <a @click="logout" class="is-clickable has-text-danger">
            <span class="icon is-small"
              ><i class="fas fa-sign-out-alt"></i
            ></span>
            <span class="is-hidden-mobile">{{ t("Log out") }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tabs {
  .is-active {
    // margin-bottom: -2px;
  }
}
</style>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      navItems: [
        {
          path: "/dash",
          name: "Dash",
          icon: "fa-tachometer-alt",
        },
        {
          path: "/customers",
          name: "Customers",
          icon: "fa-th-list",
        },
        {
          path: "/reports",
          name: "Reports",
          icon: "fa-file-excel",
        },
        {
          path: "/settings",
          name: "Settings",
          icon: "fa-cogs",
        },
      ],
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("login");
      });
    },
  },
};
</script>
