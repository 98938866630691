<template>
  <div class="SellerDropdown">
    <div
      class="dropdown is-block"
      :class="{ 'is-active': isActive }"
      v-if="isReady"
    >
      <div class="dropdown-trigger is-fullwidth">
        <button
          class="button is-fullwidth"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          @click="isActive = !isActive"
        >
          <span>{{
            ($store.state.me.activeSeller || $store.state.me.user.companies[0])
              .identifier
          }}</span>
          <span class="icon is-small">
            <i
              :class="['fas', `fa-angle-${isActive ? 'up' : 'down'}`]"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </div>

      <div
        class="dropdown-menu"
        :class="{ 'is-active': isActive }"
        id="dropdown-menu"
        role="menu"
        style="width: 100%;"
      >

          <div class="dropdown-content" style="
    overflow-y: scroll;
    max-height: 300px;
">
            <span
              class="dropdown-item is-size-7"
              :class="{
                'is-clickable':
                  (
                    $store.state.me.activeSeller ||
                    $store.state.me.user.companies[0]
                  ).identifier !== seller.identifier,
              }"
              v-for="seller in $store.state.me.user.companies"
              :key="`${seller[0]}${Math.random()}`"
              @click="changeActiveSeller(seller)"
            >
              {{ seller.identifier }}
            </span>
          </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.dropdown {
  .dropdown-trigger {
    .button {
      font-weight: $weight-semibold;
      border: none;
      background: #00000036;
      backdrop-filter: blur(2px);
      color: $white-ter;
    }
  }
  .dropdown-menu {

    .dropdown-content,
    .button {
      font-weight: $weight-semibold;
      border: none;
      background: #00000036;
      backdrop-filter: blur(2px);
      color: $white-ter;
    }
    .dropdown-item {
      color: $white-ter;
    }
  }
}

@keyframes collapse {
  0% {
    height: 0;
  }
}
</style>

<script>
import { mapGetters } from "vuex";


export default {
  mixins: [],

  data: () => ({
    isActive: false,
    sellers: [],
  }),
  computed: {
    ...mapGetters(["me"]),
    isReady() {
      return (
        this.$store.state.me.user.companies &&
        this.$store.state.me.user.companies[0]
      );
    },
  },
  methods: {
    clickAway() {
      this.isActive = false;
    },
    changeActiveSeller(seller) {
      if (
        (
          this.$store.state.me.activeSeller ||
          this.$store.state.me.user.companies[0]
        ).identifier !== seller.identifier
      ) {
        this.isActive = false;
        this.$store.dispatch("setActiveSeller", seller);
      }
    },
  },
};
</script>
