<template>
  <div id="TODO">
    <reminders-modal @close="getReminders" ref="RemindersModal" />
    <list-modal @close="getListsMetrics" ref="ListModal" />
    <new-item-modal @close="getListsMetrics" ref="NewItemModal" />
    <overdue-payment-plans-modal @close="getOverduePlanActions" ref="OverduePaymentPlansModal"/>
    <overdue-payment-promises-modal @close="getOverduePromiseActions" ref="OverduePaymentPromisesModal"/>
    <div class="columns is-multiline">
      <div class="column is-4">
        <div style="height: 125px;" class="box custom__box is-clickable" @click="openRemindersModal">
          <h4 class="is-size-5 has-text-weight-bold">
            <span>{{ actions.remindersReady.length }}</span>
            <span class="icon is-small is-pulled-right" v-if="actions.loadingReminders">
              <span class="fa fa-spin fa-spinner is-size-6"></span>
            </span>
          </h4>
          <small class="mt-2 is-block is-size-7">{{ t("Reminders") }}</small>
        </div>
      </div>
      <div
        class="column is-4"
        :key="list.identifier"
        v-for="list in listsReady"
      >
        <div style="height: 125px;" class="box custom__box is-clickable" @click="openListModal(list)">
          <button
            v-if="list.name === 'Followup' || list.name === 'Callback'"
            class="is-small button  is-primary is-rounded is-pulled-right ml-2 mb-1"
            :disabled="!$route.params.company_id"
            @click.prevent.stop="openNewItemModal(list)"
          >
            <span class="icon">
              <span class="fas fa-plus"></span>
            </span>
          </button>
          <h4 class="is-size-5 has-text-weight-bold">{{ list.items }}</h4>
          <small class="mt-2 is-block is-size-7"
            >{{ t(`${list.name} actions`) }}
            <template v-if="$route.params.company_id">{{
              t("for this customer.")
            }}</template></small
          >
        </div>
      </div>
      <div class="column is-4">
        <div style="height: 125px;" class="box custom__box is-clickable" @click="openOverduePaymentPlans">
          <h4 class="is-size-5 has-text-weight-bold">
            {{ overduePlans.length }}
          </h4>
          <small class="mt-2 is-block is-size-7">{{ t("Overdue Payment Plans") }}</small>
        </div>
      </div>
      <div class="column is-4">
        <div style="height: 125px;" class="box custom__box is-clickable" @click="openOverduePaymentPromises">
          <h4 class="is-size-5 has-text-weight-bold">
            {{ overduePromises.length }}
          </h4>
          <small class="mt-2 is-block is-size-7">{{ t("Overdue Payment Promises") }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.custom__box {
  border: none;
  background: #00000036;
  backdrop-filter: blur(2px);
  color: $white-ter;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import RemindersModal from "@/views/TODO/Modals/RemindersModal";
import ListModal from "@/views/TODO/Modals/ListModal";
import NewItemModal from "@/views/TODO/Modals/NewItemModal";
import OverduePaymentPlansModal from "@/views/TODO/Modals/OverduePaymentPlansModal";
import OverduePaymentPromisesModal from "@/views/TODO/Modals/ OverduePaymentPromisesModal";
export default {
  name: "TODO",
  components: {OverduePaymentPromisesModal, OverduePaymentPlansModal, NewItemModal, ListModal, RemindersModal },
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
      listsReady: [],
      overduePlans: [],
      overduePromises: [],
      reloadDataInterval: null,
    };
  },

  computed: {
    ...mapState(["actions"]),
  },
  watch: {
    "$store.state.me.activeSeller"() {
      this.actions.remindersReady = [];
      this.listsReady = [];
      this.overduePlans = [];
      this.overduePromises = [];
      this.getReminders();
      this.getListsMetrics();
      this.getOverduePlanActions();
      this.getOverduePromiseActions();
    },
    "$route.params.company_id"() {
      console.log("%c this.$route.params", "color: orange");
      console.log(this.$route.params);
      // this.listsReady = [];
      // this.overduePlans = [];
      // this.overduePromises = [];
      // this.getReminders();
      this.getListsMetrics();
      this.getOverduePlanActions();
      this.getOverduePromiseActions();
    },
  },
  mounted() {
    this.actions.remindersReady = [];
    this.listsReady = [];
    this.overduePlans = [];
    this.overduePromises = [];
    this.getReminders();
    this.getListsMetrics();
    this.getOverduePlanActions();
    this.getOverduePromiseActions();
    this.reloadDataInterval = setInterval(() => {
      this.getReminders();
      this.getListsMetrics();
      this.getOverduePlanActions();
      this.getOverduePromiseActions();
    }, 120 * 1000);
    this.$store.state.emitter.$on("importCompleted", () => {
      this.getReminders();
      this.getListsMetrics();
      this.getOverduePlanActions();
      this.getOverduePromiseActions();
    });
  },
  beforeUnmount() {
    clearInterval(this.reloadDataInterval);
    this.reloadDataInterval = null;
  },
  methods: {
    ...mapActions(["getReminders"]),
    async getListsMetrics() {
      console.log("getting list Metrics");
      try {
        let data = await this.$store.dispatch("dataviewKit/getViewData", {
          view: "cm_incaze_nl_action_metrics",
          paginated: 0,
          parameters: {
            owner_identifier:
              this.$route.params.company_id ??
              (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
              ).identifier,
          },
        });

        if (!data.success) {
          throw data;
        }
        this.listsReady = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getOverduePlanActions() {
      try {
        let data = await this.$store.dispatch("dataviewKit/getViewData", {
          view: "cm_incaze_nl_all_payment_plans",
          paginated: 0,
          parameters: {
            owner_identifier:
              this.$route.params.company_id ??
              (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
              ).identifier,
          },
          filters: {
            next_payment: {
              operand: '<',
              value: this.$store.getters[`Formatting/getFormattedDate`]((new Date())),
            }
          }
        });

        if (!data.success) {
          throw data;
        }
        this.overduePlans = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getOverduePromiseActions() {
      try {
        let data = await this.$store.dispatch("dataviewKit/getViewData", {
          view: "cm_incaze_nl_all_payment_promises",
          paginated: 0,
          parameters: {
            owner_identifier:
              this.$route.params.company_id ??
              (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
              ).identifier,
          },
          filters: {
            next_payment: {
              operand: '<',
              value: this.$store.getters[`Formatting/getFormattedDate`]((new Date())),
            }
          }
        });

        if (!data.success) {
          throw data;
        }
        this.overduePromises = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    openRemindersModal() {
      this.$refs.RemindersModal.open({
        owner_identifier: (
          this.$store.state.me.activeSeller ??
          this.$store.state.me.user.companies[0] ?? { identifier: "" }
        ).identifier,
        reminders: this.actions.remindersReady,
      });
    },
    openOverduePaymentPlans() {
      this.$refs.OverduePaymentPlansModal.open({
        owner_identifier: (
          this.$store.state.me.activeSeller ??
          this.$store.state.me.user.companies[0] ?? { identifier: "" }
        ).identifier,
        items: this.overduePlans,
      });
    },
    openOverduePaymentPromises() {
      this.$refs.OverduePaymentPromisesModal.open({
        owner_identifier: (
          this.$store.state.me.activeSeller ??
          this.$store.state.me.user.companies[0] ?? { identifier: "" }
        ).identifier,
        items: this.overduePromises,
      });
    },
    openListModal(list) {
      this.$refs.ListModal.open({
        owner_identifier: (
          this.$store.state.me.activeSeller ??
          this.$store.state.me.user.companies[0] ?? { identifier: "" }
        ).identifier,
        list: list,
      });
    },
    openNewItemModal(list) {
      this.$refs.NewItemModal.open({
        owner_identifier: (
          this.$store.state.me.activeSeller ??
          this.$store.state.me.user.companies[0] ?? { identifier: "" }
        ).identifier,
        list: list,
      });
    },
  },
};
</script>
