<template>

  <div class="reminder-select">
    <div class="field">
      <div class="control select is-small is-fullwidth">
        <select @click.prevent.stop class="" v-model="currentFlow">
          <option v-for="flow in row.flows" :key="flow.identifier" :value="flow.identifier">
            {{ t(flow.name) }}
          </option>
        </select>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'ReminderSelect',
  props: {
    row: {
      required: true,
    },
  },
  data() {
    return {
      currentFlow:null,

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  watch: {
    currentFlow(value) {
      this.$emit('changed', this.row, value);
    }
  },
  created() {
    this.currentFlow = this.row.next_reminder;
  },
  mounted() {
    this.currentFlow = this.row.next_reminder;
  },

}
</script>