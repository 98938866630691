import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;

export default {
  state: () => ({
    remindersReady: [],
    loadingReminders: false,
  }),
  mutations: {
    newReminders(state, newReminders) {
      state.remindersReady = newReminders;
    },
    setLoadingReminders(state, loadingReminders) {
      state.loadingReminders = loadingReminders;
    },
    addReminders(state, newReminders) {
      state.remindersReady = [...state.remindersReady, ...newReminders];
    },
  },
  actions: {
    async getReminders({ commit, dispatch, rootState }) {
      commit("setLoadingReminders", true)
      let reminders = await dispatch(
        "flowKit/getCmtAvailableReminders",
        {
          owner_company: (
              rootState.me.activeSeller ??
              rootState.me.user.companies[0] ?? { identifier: "" }
          ).identifier,
        },
        { "content-type": "application/json" }
      );
      commit("setLoadingReminders", false)
      if (reminders?.success) {
        commit(
          "newReminders",
          reminders.customers.map((reminder) => {
            return {
              ...reminder,
            };
          })
        );
      } else {
        throw reminders;
      }

    },
  },
  getters: {},
};
