import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async getCompany({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/company`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getOwnedCompany({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/owned/company`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getOwnedCompanySettings({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/owned/company/settings`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

    async getCompanySettings({ dispatch }, payload = {}, headers = {}) {
      let response = await axios({
        url: `${await dispatch("getEnv", "organizational.kit.domain", {
          root: true,
        })}/api/company/settings`,
        params: payload,
        headers: headers,
        method: "GET",
      });
      if (response.data.success) {
        return response.data;
      } else {
        throw response.data;
      }
    },
async putCompany({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/company`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putOwnedCompany({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/owned/company`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putOwnedCompanySettings({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/owned/company/settings`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

    async putCompanySettings({ dispatch }, payload = {}, headers = {}) {
      let response = await axios({
        url: `${await dispatch("getEnv", "organizational.kit.domain", {
          root: true,
        })}/api/company/settings`,
        data: payload,
        headers: headers,
        method: "PUT",
      });
      if (response.data.success) {
        return response.data;
      } else {
        throw response.data;
      }
    },
    async createCompanySettings({ dispatch }, payload = {}, headers = {}) {
      let response = await axios({
        url: `${await dispatch("getEnv", "organizational.kit.domain", {
          root: true,
        })}/api/company/settings`,
        data: payload,
        headers: headers,
        method: "POST",
      });
      if (response.data.success) {
        return response.data;
      } else {
        throw response.data;
      }
    },
async getCompanyStakeholders({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/company/stakeholders`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async getOwnedCompanyStakeholders({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/owned/company/stakeholders`,
    params: payload,
    headers: headers,
    method: "GET",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putCompanyStakeholder({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/company/stakeholder`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async putOwnedCompanyStakeholder({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "organizational.kit.domain", {
      root: true,
    })}/api/owned/company/stakeholder`,
    data: payload,
    headers: headers,
    method: "PUT",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
    async postUserData({ dispatch }, payload = {}, headers = {}) {
      let response = await axios({
        url: `${await dispatch("getEnv", "organizational.kit.domain", {
          root: true,
        })}/api/owned/company/user/create`,
        params: payload,
        headers: headers,
        method: "PUT",
      });
      if (response.data.success) {
        return response.data;
      } else {
        throw response.data;
      }
    },
  },
  getters: {
        getCompanyStatus: (state) => state.getCompanyStatus,
    getOwnedCompanyStatus: (state) => state.getOwnedCompanyStatus,
    getOwnedCompanySettingsStatus: (state) => state.getOwnedCompanySettingsStatus,
    putCompanyStatus: (state) => state.putCompanyStatus,
    putOwnedCompanyStatus: (state) => state.putOwnedCompanyStatus,
    putOwnedCompanySettingsStatus: (state) => state.putOwnedCompanySettingsStatus,
    getCompanyStakeholdersStatus: (state) => state.getCompanyStakeholdersStatus,
    getOwnedCompanyStakeholdersStatus: (state) => state.getOwnedCompanyStakeholdersStatus,
    putCompanyStakeholderStatus: (state) => state.putCompanyStakeholderStatus,
    putOwnedCompanyStakeholderStatus: (state) => state.putOwnedCompanyStakeholderStatus,
  },
};