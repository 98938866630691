<template>
  <div id="navbar">
    <div class="tabs mb-4 is-fullwidth mb-3 is-size-6">
      <ul>
        <li
          v-for="(navItem, index) in navItems"
          :key="navItem.icon"
          :class="{
            'is-active': $route.matched.some(
              (route) => route.path === navItem.path
            ),
          }"
          class="is-light m-2"
        >
          <a
            @click.prevent="
              $emit('routeClick', {
                navItem,
                index,
                oldIndex: navItems.findIndex((nI) =>
                  $route.matched.some((route) => route.path === nI.path)
                ),
              })
            "
          >
            <span class="icon is-small"
              ><i :class="['fas','is-hidden-mobile', navItem.icon]"></i
            ></span>
            <span class="">{{ t(navItem.name) }}</span>
          </a>
        </li>
        <li>
          <a @click="openRemindersModal" class="is-clickable has-text-danger">
            <span class="">{{ t("Reminders") }}</span>
          </a>
        </li>
        <li>
          <a @click="logout" class="is-clickable has-text-danger">
            <span class="icon is-small"
              ><i class="fas fa-sign-out-alt"></i
            ></span>
            <span class="">{{ t("Log out") }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <reminders-mobile-modal @close="getReminders" ref="RemindersModal" />

</template>

<style lang="scss" scoped>
.tabs {
  .is-active {
    // margin-bottom: -2px;
  }
}
</style>

<script>

import { mapActions, mapState } from "vuex";
import RemindersMobileModal from "@/views/TODO/Modals/RemindersMobileModal.vue";

export default {
  name: "NavbarMobile",
  components: {RemindersMobileModal},

  watch: {

  },
  computed: {
    ...mapState(["actions"]),
  },
  data() {
    return {
      navItems: [
        {
          path: "/customers",
          name: "Invoicing",
          icon: "",
        },
        {
          path: "/reports",
          name: "Reports",
          icon: "fa-file-excel",
        },
        {
          path: "/new_customer",
          name: "New Customer",
          icon: "fa-cogs",
        },
      ],
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
    };
  },
  mounted() {},
  methods: {
    ...mapActions(["getReminders"]),
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("login");
      });
    },

    openRemindersModal() {
      console.log(this.$refs.RemindersModal)
      this.$refs.RemindersModal.open({
        owner_identifier: (
            this.$store.state.me.activeSeller ??
            this.$store.state.me.user.companies[0] ?? { identifier: "" }
        ).identifier,
        reminders: this.actions.remindersReady,
      });
    },
  },

};
</script>
