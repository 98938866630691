<template>
  <div id="CreateNoteModal">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ t('New note') }} {{ owner_identifier }}
            <span class="icon is-pulled-right" v-if="isLoading">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button class="delete" aria-label="close"  v-if="!isLoading" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0">
          <textarea name="note" v-model="note" id="note" rows="5" class="textarea"></textarea>
          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span
              class="card-footer-item has-text-danger is-clickable"
              :disabled="isLoading"
              @click="close"
          >
            {{ t('Cancel') }}
          </span>
          <span
              class="card-footer-item has-text-info is-clickable"
              :disabled="isLoading"
              @click="send"
          >
            {{ t('Confirm') }}
          </span>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'CreateNoteModal',
  data () {
    return{
      isOpen: false,
      isLoading: false,
      owner_identifier: null,
      parent_identifier: null,
      note: '',
      settingsData: {},
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  computed: {
  },
  methods: {
    open(values) {
      console.log(values);

      this.owner_identifier = values.owner_identifier;
      this.parent_identifier = values.parent_identifier;

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
    async send() {
      const {
        owner_identifier,
        parent_identifier,
          note
      } = this;
      try {
        let data = await this.$store.dispatch("noteKit/putNote", {
          owner_company: owner_identifier,
          parent_company: parent_identifier,
          note: note,
        });
        this.note = '';
        this.$store.state.emitter.$emit('newNote');
        if (!data.success) {
          throw data;
        }

        this.close();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
