<template>
  <div id="Notes">
    <article class="message custom__box">
      <div class="message-header custom__header is-size-5">
        <p>
          {{ t("Notes") }}
          <button
            :disabled="!$route.params.company_id"
            class="is-small button is-primary is-rounded is-pulled-right ml-3"
            @click="openCreateNoteModal"
          >
            <span class="icon">
              <span class="fa fa-plus"></span>
            </span>
          </button>
        </p>
        <Tag
          v-if="$route.params.company_id"
          :name="$route.params.company_id"
          :value="$route.params.company_name"
          classes="is-primary"
        />
      </div>
      <div class="message-body" v-if="!notes.loading || keepDisplay">
        <div
          v-for="note in notes.notes"
          :key="`${JSON.stringify(note)}${Math.random()}`"
          :class="[
            ...(note.classes || []),
            'is-info',
            'card',
            'custom__card',
            'is-shadowless',
            'mb-4',
            {
              'is-clickable': note.emitter !== null,
            },
          ]"
          @click="emmitEvent(note)"
        >
          <div class="card-content">
            <div class="content">
              <span class=" has-text-grey-light is-size-7 is-pulled-right">{{
                note.timeago
              }}</span>
<!--              <span class="is-block is-size-5	has-text-weight-bold">{{-->
<!--                note.title-->
<!--              }}</span>-->
              <span v-html="note.description"></span>
            </div>
          </div>
        </div>
        <template v-if="notes.notes.length === 0">
          <div class="card-content">
            <div class="content">
              <span
                class="is-block is-size-5	has-text-weight-bold has-text-white-ter has-text-centered px-6"
                >{{
                  t(
                    "There are no notes available at this moment for this customer."
                  )
                }}</span
              >
            </div>
          </div>
        </template>
      </div>
      <div class="message-body" v-if="notes.loading && !keepDisplay">
        <p class="is-block is-size-5	has-text-weight-bold has-text-white-ter has-text-centered px-6">
          <span class="icon mr-2">
            <span class="fa fa-spin fa-spinner"></span>
          </span>
          <span class="has-text-white-ter">{{ t('Loading your notes...') }}</span>
        </p>
      </div>
    </article>
    <CreateNoteModal ref="CreateNoteModal" @close="getNotes" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.custom__box {
  border: none;
  background: #00000036;
  backdrop-filter: blur(2px);
  color: $white-ter;
}
.custom__card {
  border: none;
  background: #00000036;
  // backdrop-filter: blur(2px);
  color: $white-ter;
}
.custom__header {
  border: none;
  background: #00000036;
  // backdrop-filter: blur(2px);
  color: $white-ter;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import Tag from "@/components/Dynamic/Tag.vue";
import CreateNoteModal from "@/views/Notes/Modals/CreateNoteModal";

export default {
  name: "Notes",
  components: { CreateNoteModal, Tag },
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](
        this.$options.name
      ),
      notesInterval: null,
      keepDisplay: false,
    };
  },

  computed: {
    ...mapState(["notes"]),
  },
  watch: {
    "$store.state.me.activeSeller"() {
      this.notes.notes = [];
      this.getNotes();
    },
    "$route.params.company_name"() {
      this.notes.notes = [];
      this.getNotes();
    },
  },
  created() {
    this.notes.notes = [];
    this.getNotes();
    this.startTimeAgo();
  },
  mounted() {
    this.$store.state.emitter.$on("newNote", () => {
      this.getNotes();
    });
    this.notesInterval = setInterval(() => {
      this.keepDisplay = true;
      this.getNotes();
    }, 20 * 1000);
  },
  beforeUnmount() {
    clearInterval(this.notesInterval);
  },
  methods: {
    ...mapActions(["getNotes", "startTimeAgo"]),
    openCreateNoteModal() {
      this.$refs.CreateNoteModal.open({
        owner_identifier: this.$route.params.company_id
          ? this.$route.params.company_id
          : null,
        parent_identifier: this.$route.params.parent_identifier,
      });
    },
    emmitEvent(note) {
      if (note.emitter !== null) {
        let [event, payload] = note.emitter.split(":");

        this.$store.state.emitter.$emit(event, payload);
      }
    },
  },
};
</script>
