import axios from "axios";
axios.defaults.headers.common["X-APP-KIT-TOKEN"] =
  process.env.VUE_APP_APP_KIT_TOKEN;
export default {
  namespaced: true,
  actions: {
    
async postExportCsv({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "export.kit.domain", {
      root: true,
    })}/api/export/csv`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postExportSimpleDataset({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "export.kit.domain", {
      root: true,
    })}/api/export/simple/dataset`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},

async postExportViewDataset({ dispatch }, payload = {}, headers = {}) {
  let response = await axios({
    url: `${await dispatch("getEnv", "export.kit.domain", {
      root: true,
    })}/api/export/view/dataset`,
    data: payload,
    headers: headers,
    method: "POST",
  });
  if (response.data.success) {
    return response.data;
  } else {
    throw response.data;
  }
},
  },
  getters: {
        postExportCsvStatus: (state) => state.postExportCsvStatus,
    postExportSimpleDatasetStatus: (state) => state.postExportSimpleDatasetStatus,
    postExportViewDatasetStatus: (state) => state.postExportViewDatasetStatus,
  },
};