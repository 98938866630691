/* eslint-disable no-useless-escape */
import path from "path"
let languages = {}

require
  .context("./", true, /^.*\.json$/)
  .keys()
  .forEach((lang) => {
    let file = require(`${lang}`)
    languages[path.basename(lang, ".json")] = file
  })

export default {
  namespaced: true,
  state: {
    translations: languages,
    currentLang: "en",
  },
  mutations: {
    setCurrentLang(state, lang) {
      state.currentLang = lang
    },
  },
  actions: {},
  getters: {
    getCurrentLanguage: (state) => () => {
      return state.currentLang
    },
    getTranslations: (state) => (component) => {
      let result = {}
      Object.keys(state.translations).forEach((lang) => {
        result[lang] = {}

        Object.keys(state.translations[lang]).forEach((key) => {
          if (new RegExp(`((^${component})\..*)`, "i").test(key)) {
            result[lang][key.replace(`${component}.`, "")] =
              state.translations[lang][key]
          }
        })
      })
      return (key, replace = {}) => {
        if (!result[state.currentLang][key]) {
          // console.log(`'${key}' not translated in: ${component}`);
          // console.log(`"${component}.${key}": "${key}"`);
        }
        let string = result[state.currentLang][key] ?? key
        for (let key in replace) {
          string = string.replace(`%${key}%`, replace[key])
        }
        return string
      }
    },
    // getTranslations: (state) => (component) => {
    //   let result = {};
    //   Object.keys(state.translations[state.currentLang]).forEach((key) => {
    //     if (new RegExp(`((^${component})\..*)`, "i").test(key))
    //       result[key.replace(`${component}.`, "")] =
    //         state[state.currentLang][key];
    //   });

    //   return (key) => {
    //     if (!result[key]) {
    //       // console.log(`'${key}' not translated in: ${component}`);
    //       console.log(`"${component}.${key}": "${key}"`);
    //     }
    //     return result[key] ?? key;
    //   };
    // },
  },
}
