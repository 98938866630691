<template>
  <div id="SidePanel">
    <div class="columns is-multiline">
      <div class="column is-6 py-0">
        <SellerDropdown />
      </div>
      <div class="column is-6 py-0">
        <TranslationsDropdown />
      </div>
    </div>
    <TODO />
    <div class="columns mt-5">
      <div class="column is-12">
        <Notes v-if="$route.params.company_id" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#SidePanel {
  animation: toLeft 1s ease-in-out;
}

@keyframes toLeft {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}
</style>

<script>
import Notes from "./Notes.vue";
import SellerDropdown from "./SellerDropdown.vue";
import TranslationsDropdown from "./TranslationsDropdown.vue";
import TODO from "@/components/SidePanel/TODO";

import { mapState } from "vuex";

export default {
  components: {
    TODO,
    SellerDropdown,
    TranslationsDropdown,
    Notes,
  },
  computed: {
    ...mapState(["route"]),
  },
};
</script>
