<template>

  <div class="invoice-options">
    <template v-if="row.dispute_identifier !== null">
      <button class="button is-small is-rounded is-pulled-right ml-2" @click.prevent.stop="$store.state.emitter.$emit('viewDispute', row.dispute_identifier)">
        {{t('Open Dispute')}}
      </button>
    </template>
    <template v-if="row.promise_identifier !== null">
      <button class="button is-small is-rounded is-pulled-right ml-2" @click.prevent.stop="$store.state.emitter.$emit('viewPaymentPromise', row.promise_identifier)">
        {{t('Open Payment Promise')}}
      </button>
    </template>
    <template v-if="row.plan_identifier !== null">
      <button class="button is-small is-rounded is-pulled-right ml-2" @click.prevent.stop="$store.state.emitter.$emit('viewPaymentPlan', row.plan_identifier)">
        {{t('Open Payment Plan')}}
      </button>
    </template>
    <template v-if="row.invoice_url !== null">
      <button class="button is-small is-rounded is-pulled-right ml-2" @click.prevent.stop="openInvoiceUrl">
        {{t('PDF')}}
      </button>
    </template>
  </div>

</template>
<script>

export default {
  name: 'InvoiceOptions',
  props: {
    row: {
      required: true,
    },
  },
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    openInvoiceUrl() {
      window.open(this.row.invoice_url, '_blank')
    }
  },
}
</script>